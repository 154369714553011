<template>
  <div class="hmc-intro">
    <div class="mobile">
      <h1>Profesionalismo, Experiencia, Dedicación</h1>
    </div>
    <div class="content">
      <h1>Profesionalismo, Experiencia, Dedicación</h1>
      <p>
        Ubicada en el corazón de Tirana, nuestra clínica está formada por
        dentistas altamente cualificados y un equipo amable. Ofrecemos servicios
        multilingües para acomodar a los pacientes internacionales y nos
        enfocamos en proporcionar atención dental de alta calidad y eficiente,
        con énfasis en el profesionalismo y el confort del paciente.
      </p>
      <div class="image-child-container">
        <div>
          <img
            :src="require(`../../assets/img/gallery/22.webp`)"
            alt="Profesionales dentales en acción"
          />
        </div>
        <div>
          <img
            :src="require(`../../assets/img/gallery/23.webp`)"
            alt="Profesionales dentales en acción"
          />
        </div>
        <div>
          <img
            :src="require(`../../assets/img/gallery/26.webp`)"
            alt="Profesionales dentales en acción"
          />
        </div>
        <div>
          <img
            :src="require(`../../assets/img/gallery/25.webp`)"
            alt="Profesionales dentales en acción"
          />
        </div>
        <div>
          <img
            :src="require(`../../assets/img/gallery/24.webp`)"
            alt="Profesionales dentales en acción"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentImage: require("../../assets/img/gallery/27.webp"), // Default image
    };
  },
};
</script>

<style scoped>
.hmc-intro {
  display: grid;
  grid-template-columns: 1fr;
  overflow: hidden;
}

.content {
  flex: 1;
  padding: 2rem;
}

h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 1rem;
  color: #555;
  font-size: 1.1rem;
  line-height: 1.6;
  position: relative;
  padding-left: 1.5rem;
}

li::before {
  content: "•";
  color: #5e35b1;
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  top: -0.2rem;
}

.image-container {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.img-container img:hover {
  transform: scale(1.2);
}
.image-child-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 15px;
  margin-top: 28px;
}
.image-child-container img {
  border-radius: 10px;
}

.image-child-container > div {
  transition: transform 0.3s ease;
}
.image-child-container > div:hover {
  transform: scale(1.05);
}

.overlay {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #c49754;
  color: white;
  padding: 1rem 2rem;
  border-top-left-radius: 20px;
}

h2 {
  font-size: 2rem;
  margin: 0;
}

p {
  font-size: 1.2rem;
  margin: 0;
  opacity: 0.8;
}

.hmc-intro > .mobile {
  display: none;
}

.content > h1 {
  text-align: center;
}
.content > p {
  text-align: center;
}

@media all and (max-width: 1200px) {
  .hmc-intro {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .content > h1 {
    display: none;
    text-align: center !important;
  }

  .hmc-intro > .image-container {
    display: none;
  }

  .hmc-intro > .mobile {
    display: block;
  }

  .mobile > h1 {
    text-align: center;
  }

  .image-child-container {
    margin-top: 30px;
    row-gap: 15px;
    grid-template-columns: repeat(6, 1fr);
  }

  .image-child-container > div:nth-child(1) {
    grid-column: 1 / 3;
  }
  .image-child-container > div:nth-child(2) {
    grid-column: 3 / 5;
  }

  .image-child-container > div:nth-child(3) {
    grid-column: 5 / 7;
  }
  .image-child-container > div:nth-child(4) {
    grid-column: 2 / 4;
  }
  .image-child-container > div:nth-child(5) {
    grid-column: 4 / 6;
  }

  .mobile > .image-container {
    max-width: 92.5%;
    margin: auto;
    border-radius: 10px;
  }

  .content {
    margin-top: -44px;
  }
}

@media all and (max-width: 768px) {
  .mobile > h1 {
    font-size: 1.5rem;
    line-height: 1;
  }

  p {
    font-size: 1rem;
  }

  .mobile > .image-container {
    max-width: 85.5%;
  }

  .image-child-container {
    margin-top: 30px;
    row-gap: 15px;
    grid-template-columns: repeat(4, 1fr);
  }

  .image-child-container > div:nth-child(1) {
    grid-column: 1 / 3;
  }
  .image-child-container > div:nth-child(2) {
    grid-column: 3 / 5;
  }

  .image-child-container > div:nth-child(3) {
    grid-column: 1 / 3;
  }
  .image-child-container > div:nth-child(4) {
    grid-column: 3 / 5;
  }
  .image-child-container > div:nth-child(5) {
    grid-column: 2 / 4;
  }
}
</style>
