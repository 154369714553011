<template>
  <!-- Contenitore principale della timeline -->
  <h2>Il Tuo Percorso Dentale: Guida Passo-Passo</h2>
  <div class="timeline-container">
    <!-- Punto della timeline individuale -->
    <div class="timeline-point">
      <i class="fa-solid fa-phone-volume"></i>
      <div class="popup">
        <div class="popup-number">1</div>
        <div class="popup-details">
          <div class="popup-title">Consulto Iniziale</div>
          Iniziamo mettendoci in contatto con te telefonicamente o via email.
          Durante questa conversazione, discuteremo delle tue preoccupazioni
          dentali e potrai inviarci la tua radiografia dentale. Questo ci
          aiuterà a comprendere meglio la tua situazione e a preparare le
          opzioni di trattamento più appropriate per te.
        </div>
      </div>
    </div>

    <!-- Punto della timeline individuale -->
    <div class="timeline-point">
      <i class="fa-solid fa-file-invoice"></i>
      <div class="popup">
        <div class="popup-number">2</div>
        <div class="popup-details">
          <div class="popup-title">Piano di Trattamento e Preventivo</div>
          Il nostro dentista esamina le tue radiografie e i dettagli che hai
          condiviso per creare un piano di trattamento personalizzato. Riceverai
          anche un preventivo indicativo per il trattamento. Il piano è
          flessibile e può essere aggiornato in base alle tue esigenze o
          preferenze.
        </div>
      </div>
    </div>

    <!-- Punto della timeline individuale -->
    <div class="timeline-point">
      <i class="fa-solid fa-plane"></i>
      <div class="popup">
        <div class="popup-number">3</div>
        <div class="popup-details">
          <div class="popup-title">Organizzazione del Viaggio</div>
          Rendiamo il tuo viaggio facile! A seconda del prezzo finale del
          trattamento, potremmo coprire il tuo biglietto aereo e il soggiorno in
          hotel. In ogni caso, offriamo sempre trasporto gratuito in auto di
          lusso da e per l’aeroporto e assistiamo con tutte le sistemazioni di
          viaggio per garantire un'esperienza senza problemi.
        </div>
      </div>
    </div>

    <!-- Punto della timeline individuale -->
    <div class="timeline-point">
      <i class="fa-regular fa-calendar-check"></i>
      <div class="popup">
        <div class="popup-number">4</div>
        <div class="popup-details">
          <div class="popup-title">Arrivo e Primo Appuntamento</div>
          Al tuo arrivo, iniziamo con una consultazione gratuita, una scansione
          3D gratuita e una pulizia gratuita. Incontrerai i nostri dentisti
          professionisti e, se desideri apportare modifiche al piano di
          trattamento, discuteremo il preventivo aggiornato con te.
        </div>
      </div>
    </div>

    <!-- Punto della timeline individuale -->
    <div class="timeline-point">
      <i class="fa-solid fa-tooth"></i>
      <div class="popup">
        <div class="popup-number">5</div>
        <div class="popup-details">
          <div class="popup-title">Procedura e Recupero</div>
          Il trattamento viene eseguito dai nostri dentisti esperti, garantendo
          la massima qualità delle cure. Dopo la procedura, forniamo istruzioni
          dettagliate per il recupero e siamo a tua disposizione per supportarti
          durante il processo di guarigione, per assicurarti che tutto vada per
          il meglio.
        </div>
      </div>
    </div>

    <!-- Punto della timeline individuale -->
    <div class="timeline-point">
      <i class="fa-solid fa-hospital-user"></i>
      <div class="popup">
        <div class="popup-number">6</div>
        <div class="popup-details">
          <div class="popup-title">Follow-up e Cura Post-Trattamento</div>
          Rimaniamo in contatto con te dopo il trattamento per assicurarci che
          tutto stia guarendo come previsto. Per alcune procedure, come gli
          impianti, dovrai tornare per una visita di follow-up dopo 3-6 mesi. Il
          nostro team ti guiderà attraverso tutti i passaggi necessari per la
          cura post-trattamento e si assicurerà che tu sia soddisfatto dei
          risultati.
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Global styles */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h2 {
  font-size: 44px;
  text-align: center;
  font-weight: 800;
  margin-bottom: 20px;
}

/* Body styling */
body {
  background: #212529;
  height: 100vh;
  display: grid;
  place-items: center;
  font-family: "Gilroy", sans-serif;
}

/* Timeline container styling */
.timeline-container {
  display: flex;
  gap: 10%;
  width: 100%;
  height: 134px;
  justify-content: center;
  font-weight: 900;
  font-size: 67px;
  margin-top: 260px;
  margin-bottom: 300px;
}

/* Individual timeline point styling */
.timeline-point {
  transition-duration: 0.5s;
  border-radius: 50%;
  background: linear-gradient(145deg, #1e2125, #23282c);
  box-shadow: 13.4px 13.4px 40.2px #1c1f23, -13.4px -13.4px 40.2px #262b2f;
  display: grid;
  place-items: center;
  width: 134px;
  position: relative;
  color: var(--color);
}

.timeline-point::before {
  content: "";
  width: 200px;
  height: 200px;
  z-index: -1;
  border-radius: 50%;
  position: absolute;
  background: conic-gradient(
    var(--color) var(--angle),
    transparent 0deg 360deg
  );
  animation: rotateBorder 1s linear var(--delay) forwards;
}

.timeline-point:nth-child(odd)::before {
  transform: rotate(-90deg);
}

.timeline-point:nth-child(even)::before {
  transform: rotate(90deg) scaleY(-1);
}

/* Custom property for angle */
@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

/* Common styling for timeline points */
.timeline-point:nth-child(1) {
  --color: #c1121f;
  --delay: 0s;
}

.timeline-point:nth-child(2) {
  --color: #ffc300;
  --delay: 1s;
}

.timeline-point:nth-child(3) {
  --color: #2ec4b6;
  --delay: 2s;
}

.timeline-point:nth-child(4) {
  --color: #6536ff;
  --delay: 3s;
}

.timeline-point:nth-child(5) {
  --color: #3a86ff;
  --delay: 4s;
}
.timeline-point:nth-child(6) {
  --color: #95e207;
  --delay: 5s;
}

/* Popup styling */
.popup {
  width: 444px;
  height: auto;
  max-height: 0;
  background-color: var(--color);
  display: grid;
  grid-template-columns: 16% 84%;
  position: absolute;
  color: white;
  border-radius: 30px;
  box-shadow: 6.7px 6.7px 21.4px #17191d, -6.7px -6.7px 21.4px #212529;

  transform-origin: bottom bottom;
  animation: expandPopup 0.5s linear calc(var(--delay) + 0.5s) forwards;
}

.timeline-point:nth-child(odd) .popup {
  bottom: 221.1px;
}

.timeline-point:nth-child(even) .popup {
  top: 221.1px;
}

/* Popup number styling */
.popup-number {
  grid-row: span 2;
  display: grid;
  font-size: 2rem;
  font-weight: bold;
  place-items: center;
  cursor: auto;
  animation: fadeIn 0.5s linear calc(var(--delay) + 0.7s) forwards;
  opacity: 0;
  height: 100%;
}

/* Popup title styling */
.popup-title {
  color: var(--color);
  padding-bottom: 6.7px;
  font-size: 20.1px;
  font-weight: 900;
}

/* Popup details styling */
.popup-details {
  padding: 20.1px;
  background-color: #1c1f23;
  border-radius: 30px;
  opacity: 0;
  font-weight: 500;
  user-select: none;
  cursor: auto;
  overflow: hidden;
  font-size: 0.8rem;
  text-align: justify;
  background: linear-gradient(145deg, #16181b, #22262b);
  margin: 3.35px;
  animation: fadeIn 0.5s linear calc(var(--delay) + 0.7s) forwards;
}

.popup::before {
  content: "";
  width: 6.7px;
  height: 0;
  border-radius: 20px;
  background-color: var(--color);
  position: absolute;
  left: 50%;
  top: -41.9px;
  display: flex;
  animation: drawLine 0.5s linear var(--delay) forwards;
}

.timeline-point:nth-child(odd) .popup:before {
  top: calc(100% + 47px);
  transform: rotateX(180deg);
  transform-origin: top;
}

/* Hover effect for timeline points */
.timeline-point:hover {
  background: linear-gradient(145deg, var(--color), #1e2125);
  color: white;
}

/* Animation for border rotation */
@keyframes rotateBorder {
  from {
    --angle: 0deg;
  }

  to {
    --angle: 180deg;
  }
}

/* Animation for popup expansion */
@keyframes expandPopup {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 200px;
  }
}

/* Animation for line drawing */
@keyframes drawLine {
  0% {
    height: 0%;
    opacity: 0;
  }

  100% {
    height: 33.5px;
    opacity: 1;
  }
}

/* Animation for fade-in effect */
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@media all and (max-width: 1200px) {
  body {
    height: auto;
    display: block;
  }
  .timeline-container {
    flex-direction: column;
    height: auto;
    width: 100%;
    padding: 100px 0px;
    margin-top: 80px;
    margin-bottom: 144px;
  }

  .timeline-point {
    height: 134px;
    flex-shrink: 0;
    left: -33%;
    margin: 0px auto;
    position: relative;
  }

  .timeline-point:nth-child(odd)::before {
    transform: rotate(0deg);
  }

  .timeline-point:nth-child(even)::before {
    transform: rotate(0deg) scaleX(-1);
  }

  .timeline-point .popup:before {
    display: none;
  }

  .timeline-point:nth-child(odd) .popup {
    bottom: auto;
    right: -375%;
  }

  .timeline-point:nth-child(even) .popup {
    top: auto;
    right: -375%;
  }
}

@media all and (max-width: 768px) {
  .timeline-point:nth-child(odd) .popup {
    bottom: -170%;
    right: 0;
  }

  .timeline-point:nth-child(even) .popup {
    bottom: -170%;
    right: 0;
  }

  .timeline-point {
    left: 40%;
  }

  h2 {
    font-size: 24px;
  }
}
</style>
