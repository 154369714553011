<template>
  <div class="gallery">
    <div class="gallery-grid">
      <div
        v-for="(image, index) in images"
        :key="index"
        class="gallery-item"
        @click="openFullscreen(image)"
      >
        <img :src="image.src" :alt="image.alt" />
        <div class="explanation">
          <p>{{ image.explanation }}</p>
        </div>
        <div class="image-header">
          <h3>{{ image.header }}</h3>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div
        v-if="fullscreenImage"
        class="fullscreen-view"
        @click="closeFullscreen"
      >
        <button
          class="close-button"
          @click="closeFullscreen"
          aria-label="Close fullscreen view"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
        <img :src="fullscreenImage.src" :alt="fullscreenImage.alt" />
        <h3 class="fullscreen-header">{{ fullscreenImage.header }}</h3>
        <p class="fullscreen-explanation">{{ fullscreenImage.explanation }}</p>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref } from "vue";

const images = [
  {
    src: require("../../assets/img/albania/skanderbegSquare.webp"),
    alt: "Sheshi Skënderbej:",
    header: "Sheshi Skënderbej (Sheshi Skënderbej):",
    explanation:
      "Sheshi Skënderbej: Zemra e Tiranës, ky shesh i gjerë është një pikë e domosdoshme për vizitë, i rrethuar nga monumente të rëndësishme si Muzeu Kombëtar i Historisë, Teatri i Operas dhe Xhamia Et'hem Bey. ",
  },
  {
    src: require("../../assets/img/albania/bunkArt.webp"),
    alt: "Bunk'Art",
    header: "Bunk'Art 1 & 2:",
    explanation:
      "Këto bunkere të ish-Luftës së Ftohtë janë shndërruar në muze që paraqesin historinë e Shqipërisë nga Lufta e Dytë Botërore deri në regjimin komunist.",
  },
  {
    src: require("../../assets/img/albania/dajti.webp"),
    alt: "Parku Kombëtar i Dajti",
    header: "Parku Kombëtar i Dajti:",
    explanation:
      "Duke ofruar pamje mahnitëse të Tiranës, vizitorët mund të përdorin teleferikun Dajti Ekspres për të arritur në majë, ku i presin shtegtarët, restorantet dhe zonat për piknik. ",
  },
  {
    src: require("../../assets/img/albania/grandParkThree.webp"),
    alt: "Parku i Madh",
    header: "Parku i Madh(Parku i Madh)",
    explanation:
      "Një oazë e gjelbër në qytet, perfekte për një ditë relaksuese, me një liqen artificial, Pallatin Presidencial dhe Kishën e Shën Prokopit. ",
  },
  {
    src: require("../../assets/img/albania/pyramid.webp"),
    alt: "Piramida e Tiranës",
    header: "Piramida e Tiranës",
    explanation:
      "Një monument kontroversial nga epoka komuniste, piramida është një strukturë ikonike në qytet dhe aktualisht është në proces rinovimi për t'u shndërruar në një qendër kulturore. ",
  },
  {
    src: require("../../assets/img/albania/ethemBey.webp"),
    alt: "Xhamia e Et'hem Beut",
    header: "Xhamia e Et'hem Beut:",
    explanation:
      "Kjo është zemra e Tiranës dhe sheshi kryesor publik, i emëruar sipas heroit kombëtar Gjergj Kastrioti Skënderbeu. Sheshi është i rrethuar nga monumente të rëndësishme, të tilla si Muzeu Historik Kombëtar, Xhamia e Et'hem Beut dhe Pallati i Kulturës.",
  },
  {
    src: require("../../assets/img/albania/nationalMuseum.webp"),
    alt: "Muzeu Kombëtar i Historisë",
    header: "Muzeu Kombëtar i Historisë:",
    explanation:
      "Ky muze ofron një udhëtim të thellë përmes historisë së pasur të Shqipërisë, nga Iliria e lashtë deri në kohët moderne. ",
  },
  {
    src: require("../../assets/img/albania/houseLeaves.webp"),
    alt: "Shtëpia e Gjetheve (Muzeu i Mbikëqyrjes Sekrete)",
    header: "Shtëpia e Gjetheve (Muzeu i Mbikëqyrjes Sekrete)",
    explanation:
      "Një muze që i kushtohet historisë së Shqipërisë për mbikëqyrjen sekrete gjatë regjimit komunist, duke ofruar një pamje tronditëse të së kaluarës së vendit.",
  },
  {
    src: require("../../assets/img/albania/newBazaar.webp"),
    alt: "Pazari i Ri",
    header: " (Pazari i Ri)",
    explanation:
      "Një zonë tregtare e gjallë ku vizitorët mund të përjetojnë kulturën lokale, të blejnë produkte freskët dhe të shijojnë kuzhinën tradicionale shqiptare. ",
  },
];

const fullscreenImage = ref(null);

const openFullscreen = (image) => {
  fullscreenImage.value = image;
};

const closeFullscreen = () => {
  fullscreenImage.value = null;
};
</script>

<style scoped>
.gallery {
  margin: 0 auto;
  padding: 20px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
  background-color: #f0f0f0;
}

.gallery-item img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.gallery-item:hover img {
  transform: scale(1.05);
}

.explanation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 1);
}

.gallery-item:hover .explanation {
  opacity: 1;
}

.image-header {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  text-align: center;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 1);
}

.image-header h3 {
  margin: 0;
  font-size: 1rem;
}

.fullscreen-view {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.fullscreen-view img {
  max-width: 90%;
  max-height: 80%;
  object-fit: contain;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.fullscreen-header {
  color: white;
  margin-top: 10px;
  font-size: 1.5rem;
}

.fullscreen-explanation {
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
  max-width: 80%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .fullscreen-header {
    font-size: 1.2rem;
  }

  .fullscreen-explanation {
    font-size: 0.9rem;
  }
}
</style>
