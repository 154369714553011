<template>
  <div class="image-comparison-slider" ref="container">
    <div class="images-container">
      <img
        :src="require(`@/assets/img/before-after/${beforeImg}.webp`)"
        alt="Before image"
        class="image before-image"
      />
      <img
        :src="require(`@/assets/img/before-after/${afterImg}.webp`)"
        alt="After image"
        class="image after-image"
        :style="{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }"
      />
    </div>
    <div
      class="slider-handle"
      :style="{ left: `${sliderPosition}%` }"
      @mousedown="startDragging"
      @touchstart="startDragging"
    >
      <div class="slider-button">
        <span class="slider-arrows">⇆</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, defineProps } from "vue";

defineProps({
  beforeImg: {
    type: String,
    default: "before1",
  },
  afterImg: {
    type: String,
    default: "after1",
  },
});

const container = ref(null);
const sliderPosition = ref(50);
const isDragging = ref(false);

const startDragging = (event) => {
  event.preventDefault();
  isDragging.value = true;
  document.addEventListener("mousemove", drag);
  document.addEventListener("touchmove", drag);
  document.addEventListener("mouseup", stopDragging);
  document.addEventListener("touchend", stopDragging);
};

const stopDragging = () => {
  isDragging.value = false;
  document.removeEventListener("mousemove", drag);
  document.removeEventListener("touchmove", drag);
  document.removeEventListener("mouseup", stopDragging);
  document.removeEventListener("touchend", stopDragging);
};

const drag = (event) => {
  if (!isDragging.value) return;
  const containerRect = container.value.getBoundingClientRect();
  const containerWidth = containerRect.width;
  const x =
    (event.type === "touchmove" ? event.touches[0].clientX : event.clientX) -
    containerRect.left;
  sliderPosition.value = Math.max(0, Math.min(100, (x / containerWidth) * 100));
};

onMounted(() => {
  document.addEventListener("mouseleave", stopDragging);
});

onUnmounted(() => {
  document.removeEventListener("mouseleave", stopDragging);
});
</script>

<style scoped>
.image-comparison-slider {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  overflow: hidden;
  user-select: none;
  background-color: #fff;
  padding: 6px;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 6px;
  background: linear-gradient(to right, #c29757, #8d6928);
  border-radius: 12px;
}
.images-container {
  position: relative;
  width: 100%;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.after-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slider-handle {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #fff;
  cursor: ew-resize;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-button {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.slider-arrows {
  font-size: 20px;
  color: #333;
}

@media (hover: hover) {
  .slider-button:hover {
    background-color: #f0f0f0;
  }
}

@media (max-width: 768px) {
  .slider-button {
    width: 30px;
    height: 30px;
  }

  .slider-arrows {
    font-size: 16px;
  }
}
</style>
