<template>
  <footer class="footer">
    <div class="footer-content">
      <!-- Logo Section -->
      <div class="footer-section logo-section">
        <div class="logo">
          <img
            src="../../assets/img/Logo/logoHMC.svg"
            alt="Health Dental Clinic Logo"
          />
        </div>
        <p>
          Në Klinikën Dentare Shëndetësore, ne kombinojmë teknologjinë më të
          fundit me një ekip të aftë dentarësh dhe specialistësh për të ofruar
          kujdes dentar të jashtëzakonshëm dhe të shkëlqyer për të gjithë
          pacientët tanë.
        </p>
        <social-media-icons-component
          bgColor="#4a5568"
        ></social-media-icons-component>
      </div>

      <!-- Links Section -->
      <div class="footer-section links-section">
        <h3>Linke të dobishme</h3>
        <ul>
          <li v-for="(item, index) in menuItems" :key="index">
            <template v-if="!item.isDropdown">
              <router-link :to="item.path" class="navbar-item">
                {{ item.name }}
              </router-link>
            </template>
          </li>
        </ul>
      </div>

      <!-- Contact Us Section -->
      <div class="footer-section links-section">
        <h3>Kontaktoni</h3>
        <ul>
          <li v-for="(item, index) in menuItemsSecond" :key="index">
            <template v-if="!item.isDropdown">
              <span class="footer-item-header">{{ item.name }}</span>
              <span class="footer-item-subtext" v-html="item.value"></span>
            </template>
          </li>
        </ul>
      </div>

      <!-- Subscribe Section -->
      <div class="footer-section subscribe-section">
        <h3>Abonohu në newsletter-in tonë!</h3>
        <p>
          Merrni këshilla të besueshme nga mjekët në Health Medical Center dhe
          qëndroni të azhornuar.
        </p>
        <form @submit.prevent="subscribeNewsletter">
          <div class="input-group">
            <input type="text" v-model="name" placeholder="Emri" required />
            <UserIcon class="input-icon" />
          </div>
          <div class="input-group">
            <input type="email" v-model="email" placeholder="Email" required />
            <MailIcon class="input-icon" />
          </div>
          <button type="submit" class="subscribe-btn">
            Abonohu
            <ArrowRightIcon class="btn-icon" />
          </button>
        </form>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref } from "vue";
import { UserIcon, MailIcon, ArrowRightIcon } from "lucide-vue-next";

import SocialMediaIconsComponent from "../SocialMediaIconsComponent.vue";

// Define the menu items with optional submenu for dropdowns
const menuItems = [
  { name: "Kreu", path: "/" },
  { name: "Kush Jemi", path: "/al/who-we-are" },
  { name: "Turizmi Dentar", path: "/al/dental-tourism" },
  { name: "Para & Pas", path: "/al/before-after" },
  { name: "Kontakto", path: "/al/contact" },
];

const menuItemsSecond = [
  {
    name: "",
    value: "E hënë - E premte: 9:00 - 18:00<br/>E shtunë: 9:00 - 13:00",
  },
  { name: "Telefon:", value: "+355 69 20 96 720" },
  { name: "Email:", value: "info@hmc.com.al" },
  {
    name: "Adresa:",
    value: `Rruga Kavajës<br/>
    Kompleksi i Ndërtimit të Parkut<br/>
    Ndërtesa D, Shkalla 1, Kati 2<br/>
    Tiranë, Shqipëri`,
  },
];

// Reactive state for the subscription form
const name = ref("");
const email = ref("");

// Subscription handler
const subscribeNewsletter = () => {
  // Implement newsletter subscription logic here
  console.log("Subscribed:", { name: name.value, email: email.value });
  name.value = "";
  email.value = "";
};
</script>

<style scoped>
.footer {
  background-color: #ffffff;

  padding: 2rem 0;
  border-top: 1px solid #e2e8f0;
  margin-top: 44px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  max-width: 80%;
  margin: 0 auto;
  padding: 0 1rem;
}

.footer-section {
  flex: 1;
  margin: 0 1rem;
  display: grid;
}

.logo-section p {
  color: #4a5568;
  font-size: 16px;
  line-height: 1.5;
  margin-top: 1rem;
}

.logo img {
  max-height: 60px;
}

.links-section h3,
.subscribe-section h3 {
  color: #1a202c;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.links-section ul {
  list-style-type: none;
  padding: 0;
}

.links-section li {
  margin-bottom: 0.5rem;
  position: relative; /* For dropdown positioning */
}

.navbar-item {
  color: #4a5568;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-item-header {
  color: #4a5568;
  text-decoration: none;
  transition: color 0.3s ease;
  font-weight: 600;
}

.footer-item-subtext {
  color: #4a5568;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-item:hover {
  color: #6b46c1;
}

/* Subscribe Section Styles */
.subscribe-section p {
  color: #4a5568;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.input-group {
  position: relative;
  margin-bottom: 1rem;
}

.input-group input {
  width: 100%;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  font-size: 0.9rem;
}

.input-icon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  color: #a0aec0;
  width: 1rem;
  height: 1rem;
}

.subscribe-btn {
  background-color: #c49754;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.subscribe-btn:hover {
  background-color: #553c9a;
}

.btn-icon {
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
}

/* Responsive Styles */
@media all and (max-width: 1200px) {
  .footer-content {
    max-width: 95%;
    padding: 0;
  }

  .subscribe-section {
    display: none;
  }

  .social-icons {
    justify-content: left;
  }
}

@media all and (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-section {
    margin-bottom: 2rem;
  }

  .logo-section p {
    margin-top: 0;
  }

  .footer-section {
    row-gap: 14px;
  }
}
</style>
