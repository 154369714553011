<template>
  <div class="container">
    <div class="card">
      <h2 class="card-title">
        Perché Scegliere Health Medical Center per il Tuo Trattamento Dentale
        all'Estero?
      </h2>
      <p class="card-description">
        Il nostro team di dentisti esperti e internazionalmente qualificati ti
        garantisce la migliore cura possibile. Utilizziamo tecnologie
        all’avanguardia e offriamo trattamenti a una frazione del costo rispetto
        a molti paesi in Europa e negli Stati Uniti.
      </p>
      <ul class="card-list">
        <li>
          Dentisti specializzati ed esperti, con credenziali e riconoscimento
          internazionale
        </li>
        <li>Tecnologie avanzate e strutture moderne</li>
        <li>Prezzi competitivi rispetto ai servizi dentali di altri paesi</li>
        <li>Accreditamenti e certificazioni</li>
        <li>Personale multilingue per una comunicazione facile</li>
      </ul>
    </div>
    <div class="card">
      <h2 class="card-title">
        Cure Dentali a Prezzi Accessibili: Risparmia con HMC
      </h2>
      <p class="card-description">
        Con risparmi fino al 70% sui trattamenti dentali rispetto a molti paesi
        occidentali, HMC ti consente di ricevere cure di alta qualità mantenendo
        più denaro nelle tue tasche.
      </p>
    </div>
    <div class="card">
      <h2 class="card-title">
        Rendiamo il Tuo Viaggio Facile: Assistenza per Viaggio e Soggiorno
      </h2>
      <p class="card-description">
        Ti aiutiamo a rendere la tua esperienza di turismo dentale il più
        conveniente possibile. Dai trasferimenti dall'aeroporto agli alloggi in
        hotel, il nostro team ti assisterà in ogni fase del tuo viaggio.
      </p>
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.card:nth-child(1) {
  grid-row: 1 / 3;
  grid-column: 1/ 2;
}

.card:nth-child(2) {
  grid-row: 1;
  grid-column: 2/ 3;
}

.card:nth-child(3) {
  grid-row: 2;
  grid-column: 2/ 2;
}

.card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;

  font-family: Arial, sans-serif;
}

.card-title {
  color: #2c3e50;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.card-description {
  color: #34495e;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.card-list {
  list-style-type: none;
  padding-left: 0;
}

.card-list li {
  color: #34495e;
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
  padding-left: 1.5rem;
  position: relative;
}

.card-list li::before {
  content: "✓";
  color: #3498db;
  position: absolute;
  left: 0;
  font-weight: bold;
}

@media all and (max-width: 768px) {
  .card {
    margin: 1rem;
    padding: 1.5rem;
  }

  .card-title {
    font-size: 1.25rem;
  }

  .card-description {
    font-size: 0.9rem;
  }

  .card-list li {
    font-size: 0.8rem;
  }
  .container > .card:nth-child(1) {
    grid-row: 1;
    grid-column: 1;
  }
  .container > .card:nth-child(2) {
    grid-row: 2;
    grid-column: 1;
  }
  .container > .card:nth-child(3) {
    grid-row: 3;
    grid-column: 1;
  }
}
</style>
