<template>
  <div :style="containerStyle" class="image-zoom-container">
    <img :src="imageUrl" :alt="altText" class="zoom-image" />
    <div :style="textOverlayStyle" class="text-overlay">
      <h1 :style="{ ...textShadowStyle, color: custColor }" class="header-text">
        {{ headerTextZero }}
      </h1>
      <h1 :style="{ ...textShadowStyle, color: custColor }" class="header-text">
        {{ headerTextOne }}
      </h1>
      <h1 :style="{ ...textShadowStyle, color: custColor }" class="header-text">
        {{ headerTextTwo }}
      </h1>
      <br />
      <p
        :style="{
          ...textShadowStyle,
          color: custColor,
          maxWidth: subTextMaxWidth + '%',
        }"
        class="sub-text"
      >
        {{ subText }}
      </p>
      <button
        v-if="isButton"
        class="stylish-button"
        @click="$router.push({ path: redirectTo })"
      >
        Planifica tu Viaje
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: { type: String, required: true },
    altText: { type: String, required: true },
    headerTextZero: { type: String, required: true },
    headerTextOne: { type: String, required: true },
    headerTextTwo: { type: String, required: true },
    subText: { type: String, required: true },
    isButton: { type: Boolean, default: false },
    custHeigh: { type: Number, default: 600 },
    custColor: { type: String, default: "black" }, // custColor prop for text color
    subTextMaxWidth: { type: Number, default: 35 },
    redirectTo: { type: String, default: "/" },
    isShadow: { type: Boolean, default: false },
  },
  computed: {
    containerStyle() {
      return {
        height: `${this.custHeigh}px`,
      };
    },
    textShadowStyle() {
      return this.isShadow
        ? { textShadow: "2px 2px 5px rgba(0, 0, 0, 1)" }
        : { textShadow: "none" };
    },
    textOverlayStyle() {
      return {
        right: this.overlayRight,
        bottom: this.overlayBottom,
      };
    },
  },
};
</script>

<style scoped>
.image-zoom-container {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.zoom-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  animation: zoom-effect 10s infinite alternate;
}

@keyframes zoom-effect {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.text-overlay {
  position: absolute;
  max-width: 100%;
  width: 100%;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header-text {
  font-size: 36px;
  margin: 0;
  font-weight: 700;
  margin-top: -12px;
}

.sub-text {
  font-size: 20px;
  display: block;
  margin: auto;
}

.stylish-button {
  background: #f90b18;
  border: none;
  border-radius: 20px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 40px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
  display: block;
  display: block;
  margin: auto;
  margin-top: 34px;
}

.stylish-button:hover {
  background: #6b5838;
  transform: translateY(-2px);
}

.stylish-button:focus {
  outline: none;
}

@media all and (max-width: 1200px) {
  .zoom-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 10% center;
    animation: zoom-effect 10s infinite alternate;
  }

  .text-overlay {
    color: #fff;
  }

  .header-text {
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 1) !important;
  }
  .sub-text {
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 1) !important;
    max-width: 70% !important;
    font-weight: 500;
    font-size: 16px;
  }
}

@media all and (max-width: 768px) {
  .header-text {
    font-size: 22px;
  }

  .sub-text {
    font-size: 15px;
    max-width: 94% !important;
  }

  .zoom-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 20% center;
    animation: zoom-effect 10s infinite alternate;
  }
}
</style>
