<!-- YourPageComponent.vue -->
<template>
  <LayoutHomeVue>
    <div id="grid-container">
      <section id="section-carousel">
        <slider-main-component></slider-main-component>
      </section>
      <section class="section-one section-eightpercent">
        <intro-clinic></intro-clinic>
      </section>
      <section class="section-two section-eightpercent">
        <h1>Departamentos</h1>
        <flip-card-component
          header-title="Implantes Dentales"
          header-subtitle="Los implantes dentales son raíces dentales artificiales colocadas en el hueso de la mandíbula para soportar dientes o puentes de reemplazo. Ofrecen una solución duradera y funcional para los dientes perdidos, mejorando tanto la estética como la salud oral."
          header-img="flip-card/dental-implant.svg"
        ></flip-card-component>
        <flip-card-component
          header-title="Odontología Prostética"
          header-subtitle="La odontología prostética implica el diseño, fabricación y colocación de prótesis dentales, como coronas, puentes y dentaduras. Esta especialidad tiene como objetivo restaurar la función y apariencia de los dientes dañados o faltantes, mejorando la calidad de vida general de los pacientes."
          header-img="flip-card/prosthetic.svg"
        ></flip-card-component>
        <flip-card-component
          header-title="Estética"
          header-subtitle="La odontología estética incluye diversos procedimientos destinados a mejorar la apariencia de los dientes, encías y sonrisas. Tratamientos como el blanqueamiento dental, las carillas y el bonding cosmético se enfocan en mejorar el atractivo estético mientras se preserva la salud oral."
          header-img="flip-card/aesthetic.svg"
        ></flip-card-component>
        <flip-card-component
          header-title="Ortodoncia"
          header-subtitle="La ortodoncia se centra en el diagnóstico, prevención y tratamiento de las irregularidades dentales y faciales. Esta especialidad utiliza aparatos como frenos, alineadores y otros dispositivos para corregir dientes y mandíbulas desalineadas, mejorando tanto la función como la estética."
          header-img="flip-card/orthodontics.svg"
        ></flip-card-component>
      </section>

      <section class="section-three section-eightpercent">
        <image-holder-component-two
          :image-url="require('@/assets/img/albania/Untitled-4.webp')"
          :alt-text="'Descripción de la imagen'"
          :header-text-zero="'Tu Nueva Sonrisa Te Espera en Tirana.'"
          :header-text-one="'Cuidado Dental de Clase Mundial'"
          :header-text-two="'Una Experiencia Inolvidable'"
          :sub-text="'Descubre lo fácil que es combinar tratamientos dentales de clase mundial y accesibles con una estancia inolvidable en Tirana. Explora nuestros servicios dentales, consejos de viaje y los principales atractivos de la ciudad.'"
          :is-button="true"
          redirectTo="/es/turismo-dental"
          overlayRight="-26%"
          overlayBottom="23%"
        />
      </section>

      <section class="section-four">
        <transport-component></transport-component>
      </section>
      <section class="section-five section-eightpercent">
        <div class="left-side">
          <faq-component
            info-card="yes"
            faq-header="Preguntas Frecuentes"
          ></faq-component>
        </div>
        <div class="right-side">
          <form-appointment></form-appointment>
        </div>
      </section>
    </div>
  </LayoutHomeVue>
</template>

<script>
import LayoutHomeVue from "@/layouts/LayoutHome.vue";
import SliderMainComponent from "@/components/es/SliderMainComponentES.vue";
import FaqComponent from "@/components/es/FaqComponentES.vue";
import FormAppointment from "@/components/es/FormAppointmentES.vue";
import IntroClinic from "@/components/es/IntroClinicES.vue";
import TransportComponent from "@/components/es/TransportComponentES.vue";
import FlipCardComponent from "@/components/es/FlipCardComponentES.vue";
import ImageHolderComponentTwo from "@/components/es/ImageHolderComponentTwoES.vue";

export default {
  components: {
    LayoutHomeVue,
    SliderMainComponent,
    FaqComponent,
    FormAppointment,
    IntroClinic,
    TransportComponent,
    FlipCardComponent,
    ImageHolderComponentTwo,
  },
  data() {
    return {
      faqS: [
        {
          id: 0,
          question:
            "¿Qué información necesito proporcionar para reservar una cita?",
          answer:
            "Solo necesitamos tu nombre completo y tu número de teléfono. Nuestro equipo de atención al paciente te llamará para agendar una cita en el momento que te convenga. Alternativamente, puedes fijar la hora de la cita tú mismo cuando reserves a través de nuestro sitio web.",
        },
        {
          id: 1,
          question:
            "Me siento nervioso/a cuando estoy solo/a. ¿Puedo traer a alguien conmigo?",
          answer:
            "¡Sí, claro! Queremos que te sientas relajado/a y como en casa durante tu tratamiento en Health Medical Center. Aunque estamos seguros de que te sentirás relajado/a tan pronto como entres a nuestra clínica, puedes traer a tus seres queridos o amigos para apoyo moral.",
        },
        {
          id: 2,
          question:
            "¿Con cuánto tiempo de antelación debo llegar para mi cita?",
          answer:
            "Depende de ti qué tan pronto desees llegar a tu cita. Si prefieres ahorrar tiempo, puedes llegar justo a la hora de tu cita. Sin embargo, te recomendamos que llegues un poco antes para disfrutar de una taza de té o café con nosotros, relajarte en una de nuestras sillas de masaje o simplemente disfrutar del ambiente tranquilo y la música relajante en nuestra sala de espera.",
        },
        {
          id: 3,
          question:
            "¿Qué pasa si necesito cambiar la fecha o la hora de mi cita?",
          answer:
            "Aunque no recomendamos cambiar la fecha o la hora de tu cita a última hora, entendemos que pueden surgir emergencias en cualquier momento. Sin embargo, te pedimos que nos informes lo antes posible si no puedes asistir a tu cita. Llama al +355 69 20 96 720 y estaremos encantados de reprogramar tu cita.",
        },
        {
          id: 4,
          question: "¿Qué sucederá durante mi primera cita de revisión?",
          answer:
            "Cuando nos visites por primera vez en Health Medical Center, nos aseguraremos de que tengas una experiencia relajante y agradable. Además de un examen exhaustivo de tu salud bucal por parte de nuestros dentistas, también recibirás una radiografía panorámica (OPG) gratuita, que muestra todos los dientes y las estructuras importantes de tu boca. No solo eso, también tendrás derecho a un diseño de sonrisa gratuito durante tu primera visita y acceso gratuito a nuestros exclusivos servicios de spa en Health Medical Center.",
        },
      ],
    };
  },
};
</script>

<style>
#grid-container {
  max-width: 100%;
  row-gap: 50px;
  display: grid;
}

#section-carousel-albania {
  max-height: 600px;
  height: 600px;
}

.section-one {
  background-color: #f8f9fa;
  overflow: hidden;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
}

.section-two {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 100%;
  width: 80%;
  justify-items: center;
  background-color: #f8f9fa;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 9px;
  column-gap: 50px;
  padding-left: 34px;
  padding-right: 34px;
}

.section-two h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1.5rem;
  font-weight: 700;
  grid-column: 1/ 5;
}

.section-eightpercent .divide-two .left-side {
  padding-right: 100px;
}
.section-eightpercent .divide-two .right-side div {
  height: 100%;
}
.section-eightpercent .divide-two .right-side div img {
  max-width: 100%;
  width: 100%;
}

.section-three {
  max-width: 100%;
  margin: auto;
  width: 80%;
}

.container-general-info {
  display: flex;
  max-width: 70%;
  margin: auto;
  justify-content: space-between;
  padding: 50px 0;
}
.container-general-info > .box {
  width: 244px;
  height: 244px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  display: grid;
  justify-items: center;
  align-items: center;
}
.container-general-info > .box:hover {
  border: 1.4px solid #c49754;
}
.container-general-info > .box > div:nth-child(2) {
  font-size: 3rem;
}

.fa-custom-clinic {
  font-size: 50px;
  color: #c49754;
}

.section-five {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 44px;
}

.container-general-info > .box > div:nth-child(2),
.container-general-info > .box > div:nth-child(3) {
  color: #c49754;
}

@media all and (max-width: 1200px) {
  .section-one > .divide-two {
    grid-template-columns: repeat(1, 1fr);
    max-width: 80%;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .container-general-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 80%;
    row-gap: 50px;
    column-gap: 50px;
  }

  .container-general-info > .box {
    width: 100%;
  }

  .section-five {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 50px;
  }

  .section-one.section-eightpercent {
    max-width: 100%;
  }

  .section-two {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    width: 92%;
  }

  .section-two h1 {
    grid-column: 1 / 3;
  }

  .section-three,
  .section-five {
    width: 92%;
  }

  .section-eightpercent {
    max-width: 92%;
  }
}

@media all and (max-width: 768px) {
  #section-carousel {
    height: auto;
  }

  .section-five {
    margin-top: 0;
  }

  .section-two h1 {
    grid-column: 1 / 2;
    font-size: 2rem;
    line-height: 1;
  }

  .section-eightpercent {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
