<template>
  <div class="gallery">
    <div class="gallery-grid">
      <div
        v-for="(image, index) in images"
        :key="index"
        class="gallery-item"
        @click="openFullscreen(image)"
      >
        <img :src="image.src" :alt="image.alt" />
        <div class="explanation">
          <p>{{ image.explanation }}</p>
        </div>
        <div class="image-header">
          <h3>{{ image.header }}</h3>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div
        v-if="fullscreenImage"
        class="fullscreen-view"
        @click="closeFullscreen"
      >
        <button
          class="close-button"
          @click="closeFullscreen"
          aria-label="Close fullscreen view"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
        <img :src="fullscreenImage.src" :alt="fullscreenImage.alt" />
        <h3 class="fullscreen-header">{{ fullscreenImage.header }}</h3>
        <p class="fullscreen-explanation">{{ fullscreenImage.explanation }}</p>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref } from "vue";

const images = [
  {
    src: require("../../assets/img/albania/skanderbegSquare.webp"),
    alt: "Piazza Skanderbeg",
    header: "Piazza Skanderbeg (Sheshi Skënderbej):",
    explanation:
      "Il cuore di Tirana, questa vasta piazza è una tappa obbligata, circondata da importanti punti di riferimento come il Museo Nazionale di Storia, il Teatro dell'Opera e la Moschea Et'hem Bey.",
  },
  {
    src: require("../../assets/img/albania/bunkArt.webp"),
    alt: "Bunk'Art",
    header: "Bunk’Art 1 & 2",
    explanation:
      "Questi ex bunker della Guerra Fredda sono stati trasformati in musei che raccontano la storia dell'Albania dalla Seconda Guerra Mondiale al regime comunista. I visitatori possono esplorare installazioni artistiche ed esposizioni storiche.",
  },
  {
    src: require("../../assets/img/albania/dajti.webp"),
    alt: "Parco Nazionale del Monte Dajti",
    header: "Parco Nazionale del Monte Dajti",
    explanation:
      "Offrendo viste mozzafiato su Tirana, i visitatori possono prendere la funivia Dajti Ekspres fino alla cima, dove li aspettano sentieri escursionistici, ristoranti e aree picnic.",
  },
  {
    src: require("../../assets/img/albania/grandParkThree.webp"),
    alt: "Parco Grande di Tirana",
    header: "Parco Grande di Tirana (Parku i Madh)",
    explanation:
      "Un’oasi verde in città, perfetta per una giornata di relax, con un lago artificiale, il Palazzo Presidenziale e la Chiesa di San Procopio.",
  },
  {
    src: require("../../assets/img/albania/pyramid.webp"),
    alt: "Piramide di Tirana",
    header: "Piramide di Tirana",
    explanation:
      "Un monumento controverso dell’era comunista, la piramide è una struttura iconica della città e sta subendo restauri per diventare un centro culturale.",
  },
  {
    src: require("../../assets/img/albania/ethemBey.webp"),
    alt: "Moschea Et'hem Bey",
    header: "Moschea Et'hem Bey",
    explanation:
      "Questa è la piazza principale di Tirana e il cuore della città, intitolata all'eroe nazionale Gjergj Kastrioti Skanderbeg. La piazza è circondata da importanti punti di riferimento, come il Museo Nazionale di Storia, la Moschea Et'hem Bey e il Palazzo della Cultura.",
  },
  {
    src: require("../../assets/img/albania/nationalMuseum.webp"),
    alt: "Museo Nazionale di Storia",
    header: "Museo Nazionale di Storia",
    explanation:
      "Situato in Piazza Skanderbeg, questo museo offre un viaggio interessante attraverso la ricca storia dell'Albania, dalla Illyria antica ai tempi moderni.",
  },
  {
    src: require("../../assets/img/albania/houseLeaves.webp"),
    alt: "Casa delle Foglie",
    header: "Casa delle Foglie (Museo della Sorveglianza Segreta)",
    explanation:
      "Un museo dedicato alla storia della sorveglianza segreta in Albania durante il regime comunista, offrendo uno sguardo commovente sul passato del paese.",
  },
  {
    src: require("../../assets/img/albania/newBazaar.webp"),
    alt: "Piazza Nuova",
    header: "Piazza Nuova (Pazari i Ri)",
    explanation:
      "Un’area di mercato vivace dove i visitatori possono sperimentare la cultura locale, fare acquisti di prodotti freschi e gustare la cucina tradizionale albanese.",
  },
];

const fullscreenImage = ref(null);

const openFullscreen = (image) => {
  fullscreenImage.value = image;
};

const closeFullscreen = () => {
  fullscreenImage.value = null;
};
</script>

<style scoped>
.gallery {
  margin: 0 auto;
  padding: 20px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
  background-color: #f0f0f0;
}

.gallery-item img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.gallery-item:hover img {
  transform: scale(1.05);
}

.explanation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 1);
}

.gallery-item:hover .explanation {
  opacity: 1;
}

.image-header {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  text-align: center;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 1);
}

.image-header h3 {
  margin: 0;
  font-size: 1rem;
}

.fullscreen-view {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.fullscreen-view img {
  max-width: 90%;
  max-height: 80%;
  object-fit: contain;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.fullscreen-header {
  color: white;
  margin-top: 10px;
  font-size: 1.5rem;
}

.fullscreen-explanation {
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
  max-width: 80%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .fullscreen-header {
    font-size: 1.2rem;
  }

  .fullscreen-explanation {
    font-size: 0.9rem;
  }
}
</style>
