<template>
  <div class="banner">
    <img src="../assets/img/albania/flag.png" alt="" />
    <div class="text-container">
      <p class="typewriter top">Travel, Smile, Save!</p>
      <p class="typewriter bottom">
        Start your dental journey today with a free virtual consultation and
        discover top-tier care at a fraction of the cost.
      </p>
    </div>
    <button
      class="book-appointment"
      @click="$router.push({ path: '/contact' })"
    >
      Plan Your Trip
    </button>
  </div>
</template>

<style scoped>
.banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #c49754;
  color: #ffffff;
  padding: 20px;
  border-radius: 10px;
}

.typewriter {
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
}

.typewriter.top {
  align-self: flex-start;
}

.typewriter.bottom {
  align-self: flex-end;
}

img {
  max-width: 5%;
  width: 100%;
  border-radius: 5px;
}

.book-appointment {
  background: #ff0000;
  color: #000;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  font-weight: 600;
  white-space: nowrap; /* Prevents text wrapping */
  flex-shrink: 0; /* Prevents the button from shrinking */
}

.book-appointment:hover {
  background: #ffffff;
  color: #3d2777;
}

@media all and (max-width: 1200px) {
  .banner {
    column-gap: 10px;
  }

  img {
    max-width: 8%;
  }

  .typewriter {
    font-size: 1rem;
  }
}

@media all and (max-width: 768px) {
  .banner {
    flex-direction: column;
    row-gap: 14px;
  }

  img {
    max-width: 18%;
  }
}
</style>
