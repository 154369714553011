<template>
  <div class="social-icons">
    <a
      href="https://www.facebook.com/hmc.com.al"
      target="_blank"
      class="social-icon facebook"
      :style="bgColor ? { backgroundColor: bgColor } : {}"
    >
      <i class="fab fa-facebook-f"></i>
    </a>

    <a
      href="https://www.instagram.com/hmc.com.al"
      target="_blank"
      class="social-icon instagram"
      :style="bgColor ? { backgroundColor: bgColor } : {}"
    >
      <i class="fab fa-instagram"></i>
    </a>

    <!-- <a
      href="#"
      class="social-icon youtube"
      :style="bgColor ? { backgroundColor: bgColor } : {}"
    >
      <i class="fab fa-youtube"></i>
    </a> -->

    <a
      href="https://api.whatsapp.com/send?phone=355692096720"
      target="_blank"
      class="social-icon whatsapp"
      :style="bgColor ? { backgroundColor: bgColor } : {}"
    >
      <i class="fab fa-whatsapp"></i>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    bgColor: {
      type: String,
      default: null,
    },
  },
};
</script>

<style>
.social-icons {
  display: flex;
  gap: 13px;
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem; /* Increased size for better visibility */
  height: 2rem;
  color: white;
  text-decoration: none;
  border-radius: 0.375rem;
  transition: transform 0.2s, background-color 0.2s;
}

/* Remove background-color from individual classes if bgColor is used */
.facebook {
  background-color: #1877f2;
}

.instagram {
  background-color: #e4405f;
}

.youtube {
  background-color: #ff0000;
}

.whatsapp {
  background-color: #25d366;
}

.social-icon:hover {
  transform: scale(1.1);
}

.social-icon i {
  font-size: 1.1rem;
}
</style>
