<template>
  <div class="intro-video-container">
    <div class="overlay">
      <h2>Health Medical Center</h2>
      <p>Transform your smile with us</p>
    </div>
    <video
      ref="videoPlayer"
      class="intro-video"
      :src="videoSrc"
      @click="togglePlay"
      @ended="videoEnded"
      autoplay
      muted
      loop
      playsinline
    >
      Your browser does not support the video tag.
    </video>
    <div class="video-controls">
      <button @click="togglePlay" class="control-btn">
        {{ isPlaying ? "❚❚" : "▶" }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

const videoSrc = new URL("@/assets/video/intro.mp4", import.meta.url).href;

const videoPlayer = ref(null);
const isPlaying = ref(true);
const isMuted = ref(true);

const togglePlay = () => {
  if (videoPlayer.value.paused) {
    videoPlayer.value.play();
    isPlaying.value = true;
  } else {
    videoPlayer.value.pause();
    isPlaying.value = false;
  }
};

const videoEnded = () => {
  isPlaying.value = false;
};

onMounted(() => {
  videoPlayer.value.addEventListener("play", () => {
    isPlaying.value = true;
  });
  videoPlayer.value.addEventListener("pause", () => {
    isPlaying.value = false;
  });
  // Ensure the initial state matches the video's actual state
  isPlaying.value = !videoPlayer.value.paused;
  isMuted.value = videoPlayer.value.muted;
});
</script>

<style scoped>
.intro-video-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  background-color: #000;
  height: 660px;
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.overlay h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  color: #fff;
}

.overlay p {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
  color: #fff;
}

.intro-video {
  width: 100%;
  height: auto;
  display: block;
}

.video-controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.control-btn {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.control-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

@media all and (max-width: 1200px) {
  .intro-video-container {
    height: auto;
  }

  .overlay {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .video-controls {
    bottom: 10px;
  }

  .control-btn {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }

  .overlay h2 {
    font-size: 26px;
  }

  .overlay p {
    font-size: 16px;
    margin-top: -12px;
  }
}
</style>
