<template>
  <div class="gallery">
    <div class="gallery-grid">
      <div
        v-for="(image, index) in images"
        :key="index"
        class="gallery-item"
        @click="openFullscreen(image)"
      >
        <img :src="image.src" :alt="image.alt" />
        <div class="explanation">
          <p>{{ image.explanation }}</p>
        </div>
        <div class="image-header">
          <h3>{{ image.header }}</h3>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div
        v-if="fullscreenImage"
        class="fullscreen-view"
        @click="closeFullscreen"
      >
        <button
          class="close-button"
          @click="closeFullscreen"
          aria-label="Close fullscreen view"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
        <img :src="fullscreenImage.src" :alt="fullscreenImage.alt" />
        <h3 class="fullscreen-header">{{ fullscreenImage.header }}</h3>
        <p class="fullscreen-explanation">{{ fullscreenImage.explanation }}</p>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref } from "vue";

const images = [
  {
    src: require("../../assets/img/albania/skanderbegSquare.webp"),
    alt: "Plaza Skanderbeg",
    header: "Plaza Skanderbeg (Sheshi Skënderbej):",
    explanation:
      "El corazón de Tirana, esta amplia plaza es una visita obligada, rodeada de lugares importantes como el Museo Nacional de Historia, la Ópera y la Mezquita de Et'hem Bey.",
  },
  {
    src: require("../../assets/img/albania/bunkArt.webp"),
    alt: "Plaza Skanderbeg",
    header: "Bunk'Art 1 y 2",
    explanation:
      "Estos antiguos bunkers de la Guerra Fría se han convertido en museos que muestran la historia de Albania desde la Segunda Guerra Mundial hasta el régimen comunista. Los visitantes pueden explorar instalaciones de arte y exposiciones históricas.",
  },
  {
    src: require("../../assets/img/albania/dajti.webp"),
    alt: "Plaza Skanderbeg",
    header: "Parque Nacional del Monte Dajti",
    explanation:
      "Con impresionantes vistas de Tirana, los visitantes pueden tomar el teleférico Dajti Ekspres hasta la cima, donde esperan senderos para caminatas, restaurantes y áreas de picnic.",
  },
  {
    src: require("../../assets/img/albania/grandParkThree.webp"),
    alt: "Plaza Skanderbeg",
    header: "Parque Grande de Tirana (Parku i Madh)",
    explanation:
      "Un oasis verde en la ciudad, perfecto para un día relajante, con un lago artificial, el Palacio Presidencial y la Iglesia de San Procopio.",
  },
  {
    src: require("../../assets/img/albania/pyramid.webp"),
    alt: "Plaza Skanderbeg",
    header: "Pirámide de Tirana",
    explanation:
      "Un monumento controvertido de la era comunista, la pirámide es una estructura icónica en la ciudad y está siendo renovada para convertirse en un centro cultural.",
  },
  {
    src: require("../../assets/img/albania/ethemBey.webp"),
    alt: "Plaza Skanderbeg",
    header: "Mezquita Et'hem Bey",
    explanation:
      "Este es el corazón de Tirana y la principal plaza pública, llamada así en honor al héroe nacional Gjergj Kastrioti Skanderbeg. La plaza está rodeada de importantes lugares, como el Museo Nacional de Historia, la Mezquita Et'hem Bey y el Palacio de la Cultura.",
  },
  {
    src: require("../../assets/img/albania/nationalMuseum.webp"),
    alt: "Plaza Skanderbeg",
    header: "Museo Nacional de Historia",
    explanation:
      "Ubicado en la Plaza Skanderbeg, este museo ofrece un recorrido esclarecedor por la rica historia de Albania, desde la Iliria antigua hasta la época moderna.",
  },
  {
    src: require("../../assets/img/albania/houseLeaves.webp"),
    alt: "Plaza Skanderbeg",
    header: "Casa de las Hojas (Museo de la Vigilancia Secreta)",
    explanation:
      "Un museo dedicado a la historia de la vigilancia secreta en Albania durante el régimen comunista, ofreciendo una visión conmovedora del pasado del país.",
  },
  {
    src: require("../../assets/img/albania/newBazaar.webp"),
    alt: "Plaza Skanderbeg",
    header: "Nuevo Bazaar (Pazari i Ri)",
    explanation:
      "Una animada zona de mercado donde los visitantes pueden experimentar la cultura local, comprar productos frescos y disfrutar de la cocina tradicional albanesa.",
  },
];

const fullscreenImage = ref(null);

const openFullscreen = (image) => {
  fullscreenImage.value = image;
};

const closeFullscreen = () => {
  fullscreenImage.value = null;
};
</script>

<style scoped>
.gallery {
  margin: 0 auto;
  padding: 20px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
  background-color: #f0f0f0;
}

.gallery-item img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.gallery-item:hover img {
  transform: scale(1.05);
}

.explanation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 1);
}

.gallery-item:hover .explanation {
  opacity: 1;
}

.image-header {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  text-align: center;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 1);
}

.image-header h3 {
  margin: 0;
  font-size: 1rem;
}

.fullscreen-view {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.fullscreen-view img {
  max-width: 90%;
  max-height: 80%;
  object-fit: contain;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.fullscreen-header {
  color: white;
  margin-top: 10px;
  font-size: 1.5rem;
}

.fullscreen-explanation {
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
  max-width: 80%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .fullscreen-header {
    font-size: 1.2rem;
  }

  .fullscreen-explanation {
    font-size: 0.9rem;
  }
}
</style>
