<template>
  <div id="layout-container">
    <!-- Header Section -->
    <header>
      <component :is="currentNavBarChild"></component>
      <component :is="currentNavBar"></component>
    </header>

    <!-- Main Content -->
    <main>
      <slot></slot>
    </main>

    <!-- Footer Section -->
    <component :is="currentFooter"></component>
  </div>
</template>

<script>
import { computed, defineAsyncComponent } from "vue";
import { useRoute } from "vue-router";

// Supported languages
const supportedLanguages = ["en", "al", "it", "es"];

// Lazy-loaded NavBarChild components
const navBarChildComponents = {
  en: defineAsyncComponent(() => import("@/components/NavBarChild.vue")),
  al: defineAsyncComponent(() => import("@/components/al/NavBarChildAL.vue")),
  it: defineAsyncComponent(() => import("@/components/it/NavBarChildIT.vue")),
  es: defineAsyncComponent(() => import("@/components/es/NavBarChildES.vue")),
};

// Lazy-loaded NavBar components
const navBarComponents = {
  en: defineAsyncComponent(() => import("@/components/NavBar.vue")),
  al: defineAsyncComponent(() => import("@/components/al/NavBarAL.vue")),
  it: defineAsyncComponent(() => import("@/components/it/NavBarIT.vue")),
  es: defineAsyncComponent(() => import("@/components/es/NavBarES.vue")),
};

// Lazy-loaded Footer components
const footerComponents = {
  en: defineAsyncComponent(() => import("@/components/FooterComponent.vue")),
  al: defineAsyncComponent(() =>
    import("@/components/al/FooterComponentAL.vue")
  ),
  it: defineAsyncComponent(() =>
    import("@/components/it/FooterComponentIT.vue")
  ),
  es: defineAsyncComponent(() =>
    import("@/components/es/FooterComponentES.vue")
  ),
};

export default {
  name: "LayoutHomeVue",
  setup() {
    const route = useRoute();

    // Extract current language from the route
    const currentLanguage = computed(() => {
      const langMatch = route.path.match(/^\/(en|al|it|es)/);
      return langMatch && supportedLanguages.includes(langMatch[1])
        ? langMatch[1]
        : "en"; // Default to English if no prefix or unsupported language
    });

    // Determine the appropriate NavBarChild component
    const currentNavBarChild = computed(
      () =>
        navBarChildComponents[currentLanguage.value] || navBarChildComponents.en
    );

    // Determine the appropriate NavBar component
    const currentNavBar = computed(
      () => navBarComponents[currentLanguage.value] || navBarComponents.en
    );

    // Determine the appropriate Footer component
    const currentFooter = computed(
      () => footerComponents[currentLanguage.value] || footerComponents.en
    );

    return {
      currentNavBarChild,
      currentNavBar,
      currentFooter,
    };
  },
};
</script>
