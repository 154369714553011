<template>
  <nav-bar-child></nav-bar-child>
  <nav-bar></nav-bar>

  <generic-image-holder-component
    header="Quiénes Somos"
    subText="Comprometidos con la Excelencia en Cuidado Dental y Estético"
  ></generic-image-holder-component>

  <div id="grid-container">
    <our-vision-component></our-vision-component>

    <div class="dental-clinic">
      <div class="clinic-image"></div>
      <div class="clinic-info">
        <h1 class="clinic-name">Sobre Health Medical Center</h1>
        <p class="clinic-description">
          Bienvenidos a Health Medical Center, una clínica líder en odontología
          y estética ubicada en el corazón de Tirana, Albania. Nos
          especializamos en ofrecer tratamientos de alta calidad y accesibles a
          pacientes de todo el mundo, combinando atención experta con un enfoque
          personalizado y centrado en el paciente.
        </p>
        <p class="clinic-description">
          Nuestra clínica ofrece lo último en tecnología dental y estética, con
          un equipo de especialistas capacitados internacionalmente, dedicados a
          ofrecer resultados excepcionales. Ya sea que estés buscando implantes
          dentales, odontología estética o una transformación completa de tu
          sonrisa, adaptamos cada tratamiento a tus necesidades individuales.
        </p>

        <div class="clinic-stats">
          <div class="stat">
            <h3>20+</h3>
            <p>Años de Experiencia</p>
          </div>
          <div class="stat">
            <h3>5</h3>
            <p>Odontólogos Especialistas</p>
          </div>
          <div class="stat">
            <h3>20+</h3>
            <p>Personal de Apoyo</p>
          </div>
        </div>
        <p class="clinic-cta">
          ¡Transforma tu sonrisa con Health Medical Center en Tirana!
          <a href="/es/contact" class="contact-us"> Contáctanos hoy </a> para
          agendar tu consulta y dar el primer paso hacia una versión más
          saludable y segura de ti mismo. Estamos aquí para ofrecerte atención
          experta y un tratamiento personalizado para ayudarte a lograr la
          sonrisa que siempre has soñado.
        </p>
        <ul class="clinic-features">
          <li>Atención Dental Completa</li>
          <li>Soluciones Estéticas de Vanguardia</li>
          <li>Profesionales Dentales Calificados</li>
          <li>Planes de Tratamiento Accesibles</li>
          <li>Tecnología Dental Moderna</li>
          <li>Cuidado Personalizado Pre y Post-Tratamiento</li>
        </ul>
      </div>
    </div>

    <div class="what-makes-us-different">
      <h2>¿Qué Nos Hace Diferentes?</h2>
      <p class="intro">
        En Health Medical Center, creemos que una atención dental de primer
        nivel va más allá de la excelencia clínica. Nos enfocamos en ofrecer una
        experiencia integral para el paciente que prioriza la comodidad, la
        facilidad y la satisfacción en cada etapa de tu tratamiento. Así es como
        lo logramos:
      </p>

      <div class="card-container">
        <div v-for="(item, index) in differentiators" :key="index" class="card">
          <div
            class="card-image"
            :style="{ backgroundImage: `url(${item.image})` }"
          ></div>
          <div class="card-content">
            <div class="card-header">
              <h3>{{ item.title }}</h3>
            </div>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="what-makes-us-different">
      <h2>Nuestros Tratamientos Especializados</h2>
      <p class="intro">
        En nuestra clínica, combinamos experiencia, tecnología de vanguardia y
        un compromiso con la excelencia para ofrecer una amplia gama de
        tratamientos dentales y estéticos, que incluyen:
      </p>
      <div class="flip-card-container">
        <flip-card-component
          header-title="Implantes Dentales"
          header-subtitle="Soluciones permanentes para dientes faltantes con técnicas avanzadas de implantes."
          header-img="flip-card/implant.jpg"
        ></flip-card-component>

        <flip-card-component
          header-title="Odontología Estética"
          header-subtitle="Transformaciones de sonrisa mediante carillas, blanqueamiento dental y más."
          header-img="flip-card/cosmetic.jpg"
        ></flip-card-component>

        <flip-card-component
          header-title="Ortodoncia"
          header-subtitle="Alinea tus dientes con alineadores transparentes o brackets tradicionales."
          header-img="flip-card/orthodontics.jpg"
        ></flip-card-component>

        <flip-card-component
          header-title="Odontología Restauradora"
          header-subtitle="Coronas, puentes y rehabilitaciones completas de la boca para restaurar la función y la estética."
          header-img="flip-card/restorative.jpg"
        ></flip-card-component>

        <flip-card-component
          header-title="Tratamientos Periodontales"
          header-subtitle="Cuidado integral de las encías y limpiezas profundas para una salud oral óptima."
          header-img="flip-card/perio.jpg"
        ></flip-card-component>

        <flip-card-component
          header-title="Tratamientos Estéticos"
          header-subtitle="Incluye Botox, rellenos dérmicos y restauración capilar para realzar tu confianza y apariencia."
          header-img="flip-card/aesthetic.jpg"
        ></flip-card-component>
      </div>
      <div class="cta">
        ¿Listo para comenzar tu viaje dental con nosotros? <br />
        Contáctanos hoy para una consulta gratuita y déjanos ayudarte a lograr
        la sonrisa de tus sueños.
        <br />
        <button @click="$router.push({ path: '/contact' })">
          Cambia Tu Sonrisa
        </button>
      </div>
    </div>
  </div>
  <footer-component></footer-component>
</template>

<script setup>
import NavBar from "@/components/es/NavBarES.vue";
import NavBarChild from "@/components/es/NavBarChildES.vue";
import OurVisionComponent from "@/components/es/OurVisionComponentES.vue";
import GenericImageHolderComponent from "@/components/es/GenericImageHolderComponentES.vue";
import FooterComponent from "@/components/es/FooterComponentES.vue";
import FlipCardComponent from "@/components/es/FlipCardComponentES.vue";

const differentiators = [
  {
    title: "Planes de Tratamiento Personalizados:",
    description:
      "Entendemos que cada paciente es único, al igual que sus necesidades dentales. Por eso, nuestros dentistas crean planes de tratamiento personalizados adaptados específicamente a tus objetivos y preferencias. Al evaluar exhaustivamente tu salud dental y discutir tus expectativas, nos aseguramos de que recibas la mejor atención posible diseñada solo para ti.",
    image: require(`@/assets/img/office/12copy.webp`),
  },
  {
    title: "Experiencia y Tecnología de Clase Mundial:",
    description:
      "Nuestra clínica combina la experiencia de dentistas experimentados con tecnología de vanguardia. Desde imágenes 3D avanzadas para un diagnóstico preciso hasta técnicas mínimamente invasivas, invertimos en las últimas innovaciones para lograr resultados óptimos y asegurar una experiencia fluida para cada paciente.",
    image: require(`@/assets/img/office/12copy.webp`),
  },
  {
    title: "Soporte Integral y Seguimiento:",
    description:
      "Tu viaje con nosotros no termina con el procedimiento. Proporcionamos soporte integral post-tratamiento y planes de cuidado posterior personalizados para garantizar que tu recuperación sea suave y cómoda. Nuestro equipo dedicado siempre está disponible para responder preguntas y guiarte a lo largo del proceso de cuidado posterior.",
    image: require(`@/assets/img/office/12copy.webp`),
  },
  {
    title: "Comodidad y Conveniencia en Cada Paso:",
    description:
      "Desde el momento en que llegas, nos enfocamos en hacer que tu experiencia sea libre de estrés. Ofrecemos servicios de transporte de lujo, asistencia con los arreglos de viaje y un ambiente cálido y acogedor en la clínica. Ya sea ayudando con los planes de viaje o proporcionando comodidades durante tu visita, priorizamos tu comodidad en cada etapa.",
    image: require(`@/assets/img/office/12copy.webp`),
  },
];
</script>

<style scoped>
:root {
  --primary-color: #4a90e2;
  --text-color: #333;
  --text-color-light: #666;
  --background-light: #ffffff;
  --background-dark: #f0f0f0;
}

#grid-container {
  display: grid;
  max-width: 80%;
  margin: auto;
  margin-top: 44px;
}

.contact-us {
  text-decoration: underline;
  color: #c49754;
  font-weight: 600;
}

.dental-clinic {
  display: flex;
  max-width: 100%;
  margin: 0 auto;
  background: linear-gradient(
    135deg,
    var(--background-light) 50%,
    var(--background-dark) 50%
  );
  border-radius: 15px !important;
}

.flip-card-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 30px;
  row-gap: 30px;
}

.clinic-image {
  flex: 1;
  background-image: url("../../assets/img/office/office_0.webp");
  background-size: cover;
  background-position: center;
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.cta {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-top: 44px;
}

.cta button {
  background-color: #c49754;
  padding: 5px 30px;
  color: #fff;
  border-radius: 10px;
}
.cta button:hover {
  background-color: #fff;
  border: 1px solid #c49754;
  padding: 5px 30px;
  color: #000;
  border-radius: 10px;
}

.clinic-info {
  flex: 1;
  padding: 2rem;
}

.who-we-are {
  color: var(--text-color-light);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  text-align: left;
  font-weight: 500;
}

.clinic-name {
  font-size: 2rem;
  color: var(--text-color);
  margin-bottom: 1rem;
}

.clinic-description,
.clinic-mission {
  color: var(--text-color-light);
  margin-bottom: 1rem;
  line-height: 1.6;
}

.clinic-stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.stat {
  text-align: center;
}

.stat h3 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.stat p {
  color: var(--text-color-light);
}

.clinic-cta {
  font-style: italic;
  color: var(--text-color-light);
  margin-bottom: 1rem;
}

.clinic-features {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
}

.clinic-features li {
  color: var(--text-color-light);
  display: flex;
  align-items: center;
}

.clinic-features li::before {
  content: "✓";
  color: var(--primary-color);
  margin-right: 0.5rem;
}

@media all and (max-width: 1200px) {
  #grid-container {
    max-width: 92%;
  }

  .card-container {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .flip-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .dental-clinic {
    flex-direction: column;
  }

  .clinic-image {
    height: 300px;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
  }

  .clinic-info {
    padding: 1rem;
  }

  .clinic-name {
    font-size: 1.5rem;
  }

  .clinic-stats {
    flex-direction: column;
  }

  .stat {
    margin-bottom: 1rem;
  }

  .clinic-features {
    grid-template-columns: 1fr;
  }

  .card-container {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .flip-card-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.what-makes-us-different {
  font-family: Arial, sans-serif;
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
}

h2 {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 1rem;
}

.intro {
  font-size: 1.1rem;
  color: #666;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 2rem;
  line-height: 1.6;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.card {
  background-color: #f8f8f8;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card-image {
  width: 120px;
  background-size: cover;
  background-position: center;
  /* clip-path: polygon(0 0, 100% 0, 0 100%); */
  background-position-x: 48%;
}

.card-content {
  padding: 1.5rem;
  flex: 1;
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
  fill: #2c3e50;
}

.card h3 {
  font-size: 1.3rem;
  color: #2c3e50;
  margin: 0;
}

.card p {
  font-size: 1rem;
  color: #34495e;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .what-makes-us-different {
    padding: 1rem;
  }

  h2 {
    font-size: 2rem;
  }

  .intro {
    font-size: 1rem;
  }

  .card-container {
    grid-template-columns: 1fr;
  }

  .card {
    flex-direction: column;
  }

  .card-image {
    width: 100%;
    height: 120px;
    clip-path: polygon(0 0, 100% 0, 0 100%);
  }
}
</style>
