<template>
  <div class="container">
    <div class="column">
      <h2>Nuestra Misión</h2>
      <p>
        Nuestra misión es ofrecer atención dental de clase mundial con un
        enfoque en la comodidad, calidad y asequibilidad del paciente. Nos
        dedicamos a ofrecer tratamientos personalizados que se adapten a las
        necesidades únicas de cada paciente, asegurando resultados
        excepcionales. Ya sea atención dental o servicios estéticos selectos,
        nos comprometemos a hacer que el viaje de cada paciente con nosotros sea
        fluido, satisfactorio y transformador.
      </p>
    </div>
    <div class="column">
      <h2>Nuestra Visión</h2>
      <p>
        En Health Medical Center, nos envisionamos como un destino de primer
        nivel para atención dental y estética integral. Nuestro objetivo es
        ofrecer tratamientos excepcionales que no solo mejoren la salud, sino
        que también aumenten la confianza y el bienestar. Al combinar
        innovación, experiencia y un enfoque personalizado, nos esforzamos por
        crear sonrisas hermosas y duraderas, y experiencias que dejen un impacto
        duradero en nuestros pacientes de todo el mundo.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MissionVision",
};
</script>

<style scoped>
.container {
  display: flex;
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 15px;
}

.column {
  flex: 1;
  padding: 20px;
}
.column h2 {
  font-weight: 600;
}

.column:first-child {
  border-right: 1px solid #e0e0e0;
}

h2 {
  color: #2c3e50;
  font-size: 24px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 2px solid #c49754;
}

p {
  color: #34495e;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .column:first-child {
    border-right: none;
    border-bottom: 1px solid #e0e0e0;
  }
}
</style>
