<template>
  <nav class="navbar">
    <div class="navbar-container">
      <!-- Logo Button -->
      <button @click="navigateToHomepage" class="navbar-logo">
        <img src="../../assets/img/Logo/logoHMC.svg" alt="Logo" />
      </button>

      <!-- Language Switcher and Mobile Menu Button -->
      <div class="navbar-controls">
        <language-switcher-component class="language-switcher" />
        <button @click="toggleMobileMenu" class="navbar-mobile-toggle">
          <span v-if="!isMobileMenuOpen">☰</span>
          <span v-else>&times;</span>
        </button>
      </div>

      <!-- Desktop Menu -->
      <div class="navbar-menu">
        <template v-for="item in menuItems" :key="item.name">
          <router-link
            v-if="!item.isDropdown"
            :to="localizedPath(item.path)"
            class="navbar-item"
          >
            {{ item.name }}
          </router-link>
          <div v-else class="navbar-item navbar-dropdown">
            <button class="navbar-dropdown-toggle">{{ item.name }}</button>
            <div class="navbar-dropdown-content">
              <div class="navbar-dropdown-grid">
                <div
                  v-for="category in treatmentCategories"
                  :key="category.name"
                  class="navbar-dropdown-category"
                >
                  <h3>{{ category.name }}</h3>
                  <hr />
                  <ul>
                    <li
                      v-for="treatment in category.treatments"
                      :key="treatment.name"
                    >
                      <router-link :to="localizedPath(treatment.path)">{{
                        treatment.name
                      }}</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

      <!-- Appointment Button -->
      <button
        class="navbar-appointment"
        @click="$router.push(localizedPath('/contact'))"
      >
        Rezervo Takim
      </button>
    </div>

    <!-- Mobile Menu -->
    <div v-show="isMobileMenuOpen" class="navbar-mobile-menu">
      <template v-for="item in menuItems" :key="item.name">
        <router-link
          v-if="!item.isDropdown"
          :to="localizedPath(item.path)"
          class="navbar-mobile-item"
        >
          <i :class="item.icon"></i>
          {{ item.name }}
        </router-link>
        <div v-else class="navbar-mobile-dropdown">
          <button
            @click="toggleTreatmentsMenu"
            class="navbar-mobile-dropdown-toggle"
          >
            <i :class="item.icon"></i>
            {{ item.name }}
          </button>
          <div
            v-show="isTreatmentsMenuOpen"
            class="navbar-mobile-dropdown-content"
          >
            <div
              v-for="category in treatmentCategories"
              :key="category.name"
              class="navbar-mobile-category"
            >
              <h3>{{ category.name }}</h3>
              <ul>
                <li
                  v-for="treatment in category.treatments"
                  :key="treatment.name"
                >
                  <router-link :to="localizedPath(treatment.path)">{{
                    treatment.name
                  }}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>
      <button
        class="navbar-mobile-appointment"
        @click="$router.push(localizedPath('/contact'))"
      >
        Rezervo Takim
      </button>
    </div>
  </nav>
</template>

<script setup>
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import LanguageSwitcherComponent from "./LanguageSwitcherComponentAL.vue";
const router = useRouter();
const route = useRoute();

const isMobileMenuOpen = ref(false);
const isTreatmentsMenuOpen = ref(false);

const toggleMobileMenu = () => {
  isMobileMenuOpen.value = !isMobileMenuOpen.value;
};

const toggleTreatmentsMenu = () => {
  isTreatmentsMenuOpen.value = !isTreatmentsMenuOpen.value;
};

// Menu items
const menuItems = [
  { name: "Kreu", path: "/al", icon: "fas fa-home" },
  { name: "Kush Jemi", path: "/al/who-we-are", icon: "fas fa-users" },
  { name: "Trajtimet", path: "#", isDropdown: true, icon: "fas fa-tooth" },
  {
    name: "Turizmi Dentar",
    path: "/al/dental-tourism",
    icon: "fas fa-plane-departure",
  },
  { name: "Para & Pas", path: "/al/before-after", icon: "fas fa-images" },
  { name: "Galeria", path: "/al/gallery", icon: "fas fa-camera" },
  { name: "Kontakto", path: "/al/contact", icon: "fas fa-envelope" },
];

// Treatment categories
const treatmentCategories = [
  {
    name: "Trajtimet e përgjithshme",
    treatments: [
      { name: "Examinimi", path: "/al/examination" },
      { name: "Pastrimi i Dhëmbëve", path: "/al/teeth-cleaning" },
      { name: "Skanim 3D", path: "/al/3d-scan" },
      { name: "Ekstraktimi i Dhëmbëve", path: "/al/tooth-extraction" },
      { name: "Trajtimet e Mishrave të Dhëmbëve", path: "/al/gum-treatments" },
    ],
  },
  {
    name: "Trajtimet Rekuperuese",
    treatments: [
      { name: "Mbushje Kompozit", path: "/al/composite-filling" },
      {
        name: "Trajtimi i Kanaleve Rrënjësore",
        path: "/al/root-canal-treatment",
      },
    ],
  },
  {
    name: "Implantet Dentare",
    treatments: [
      { name: "Implanti i Vetëm", path: "/al/single-implant" },
      {
        name: "Ura e Mbështetur nga Implantet",
        path: "/al/implant-supported-bridge",
      },
      { name: "All on 4", path: "/al/all-on-four" },
      { name: "All on 6", path: "/al/all-on-six" },
      { name: "Përforcimi i Sinusit", path: "/al/sinus-lift" },
      { name: "Transplanti i Kockës", path: "/al/bone-graft" },
    ],
  },
  {
    name: "Trajtimet Protetike",
    treatments: [
      { name: "Kurorat e Zirconit", path: "/al/zirconia-crowns" },
      {
        name: "Kurora Pjesërisht Metalike dhe Pjesërisht Porcelani",
        path: "/al/porcelain-metal-crowns",
      },
      { name: "Veneerët Dentare", path: "/al/dental-veneers" },
      { name: "Proteza Dentare", path: "/al/dentures" },
    ],
  },
  {
    name: "Trajtimet Estetike",
    treatments: [
      { name: "Buzëqeshja Hollywood", path: "/al/hollywood-smile" },
      { name: "Zbardhja e Dhëmbëve ", path: "/al/teeth-whitening" },
      { name: "Fillers", path: "/al/fillers" },
      { name: "Botox", path: "/al/botox" },
      { name: "Mesoterapi", path: "/al/mesotherapy" },
    ],
  },
  {
    name: "Trajtime Ortodontike",
    treatments: [
      { name: "Drejtues Transparent", path: "/al/clear-aligners" },
      { name: "Aparatet Ortodontike", path: "/al/braces" },
    ],
  },
];

// Helper function to localize paths
const localizedPath = (path) => {
  const langMatch = route.path.match(/^\/(en|al|it)/);
  const currentLang = langMatch ? langMatch[1] : "al"; // Default to Albanian
  return currentLang === "al" ? path : `/${currentLang}${path}`;
};

// Navigate to the localized homepage
const navigateToHomepage = () => {
  const langMatch = route.path.match(/^\/(en|al|it)/);
  const currentLang = langMatch ? langMatch[1] : "al"; // Default to Albanian
  const homepagePath = currentLang === "al" ? "/al" : `/${currentLang}`;
  router.push(homepagePath);
};
</script>

<style scoped>
.navbar {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  max-width: 80%;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

.navbar-logo img {
  height: 60px;
  max-width: 100%;
}

.navbar-menu {
  display: none;
}

.navbar-item {
  color: #333;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: color 0.3s ease;
  font-weight: 600;
}

.navbar-item:hover {
  color: #c49754;
}

.navbar-dropdown {
  position: relative;
}

.navbar-dropdown-toggle {
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.navbar-dropdown-content {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  min-width: 1224px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  padding: 1rem;
  border-radius: 4px;
}

.navbar-dropdown:hover .navbar-dropdown-content {
  display: block;
}

.navbar-dropdown-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
}

.navbar-dropdown-category h3 {
  color: #c29757;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.navbar-dropdown-category hr {
  border: none;
  border-top: 1px solid #e0e0e0;
  margin-bottom: 0.5rem;
}

.navbar-dropdown-category ul {
  list-style-type: none;
  padding: 0;
}

.navbar-dropdown-category li {
  margin-bottom: 0.25rem;
}

.navbar-dropdown-category a {
  color: #333;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.navbar-dropdown-category a:hover {
  color: #c49754;
}

.navbar-menu {
  display: flex;
  align-items: center;
}

.navbar-mobile-toggle {
  display: none;
}

.navbar-appointment {
  display: block;
  background-color: #c49754;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-weight: 600;
}

.navbar-appointment:hover {
  background-color: #158c86;
}

.navbar-controls {
  display: none;
}

@media all and (max-width: 1200px) {
  .language-switcher {
    margin-right: 10px;
  }

  .navbar-controls {
    display: flex;
    z-index: 444;
  }

  .navbar-appointment {
    display: none;
  }

  .navbar-menu {
    display: none;
  }

  .navbar-logo img {
    height: 50px;
  }

  .navbar-container {
    max-width: 92%;
  }

  .navbar-menu {
    display: none;
  }

  .navbar-mobile-toggle {
    display: block;
  }

  .navbar-appointment {
    display: none;
  }

  .navbar-mobile-toggle {
    display: block;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .navbar-appointment {
    display: none;
  }

  .navbar-mobile-menu {
    background-color: #fff;
    padding: 1rem;
  }

  .navbar-mobile-item {
    display: flex;
    align-items: center;
    color: #333;
    text-decoration: none;
    padding: 0.5rem 0;
    border-bottom: 1px solid #e0e0e0;
  }

  .navbar-mobile-item i {
    margin-right: 0.5rem;
    min-width: 20px;
    text-align: center;
  }

  .navbar-mobile-dropdown-toggle {
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    color: #333;
    font-size: 1rem;
    padding: 0.5rem 0;
    cursor: pointer;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
  }

  .navbar-mobile-dropdown-toggle i {
    margin-right: 0.5rem;
    min-width: 20px;
    text-align: center;
  }

  .navbar-mobile-dropdown-content {
    padding-left: 1rem;
  }

  .navbar-mobile-category h3 {
    color: #c49754;
    margin: 1rem 0 0.5rem;
  }

  .navbar-mobile-category ul {
    list-style-type: none;
    padding: 0;
  }

  .navbar-mobile-category li {
    margin-bottom: 0.25rem;
  }

  .navbar-mobile-category a {
    color: #333;
    text-decoration: none;
    font-size: 0.9rem;
  }

  .navbar-mobile-appointment {
    display: block;
    width: 100%;
    background-color: #c49754;
    color: #fff;
    border: none;
    padding: 0.75rem;
    margin-top: 1rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }

  .navbar-mobile-appointment:hover {
    background-color: #158c86;
  }
}
</style>
