<template>
  <div id="faq-segment">
    <info-card v-if="infoCard == 'yes'"></info-card>

    <h3 v-if="faqHeader">{{ faqHeader }}</h3>

    <section class="accordion" v-for="f in faqS" :key="f.id">
      <div>
        <div class="accordion-item">
          <button class="accordion-header">
            {{ f.question }}
          </button>
          <div class="accordion-content">
            <p v-html="f.answer"></p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import InfoCard from "@/components/al/InfoCardAL.vue";

export default {
  components: { InfoCard },
  props: {
    infoCard: {
      type: String,
      default: null,
    },
    faqHeader: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      faqS: [
        {
          id: 0,
          question:
            "Çfarë informacioni duhet të jap për të rezervuar një takim?",
          answer:
            "Na nevojiten vetëm emri juaj i plotë dhe numri juaj i telefonit. Ekipi ynë i kujdesit për pacientët do t'ju telefonojë për të rezervuar një takim në kohën që ju përshtatet. Alternativisht, mund të caktoni vetë kohën e takimit kur rezervoni një takim me ne përmes faqes sonë të internetit. ",
        },
        {
          id: 1,
          question:
            "Ndihem nervoz kur jam vetëm. A mund të sjell dikë me vete?",
          answer:
            "Sigurisht, pse jo? Ne duam që ju të ndiheni të relaksuar dhe si në shtëpi gjatë trajtimit tuaj në SparkDent. Megjithëse jemi të sigurt që do të ndiheni menjëherë të relaksuar sapo të hyni në zyrën tonë, mund të sillni të dashurit apo miqtë tuaj për mbështetje morale.",
        },
        {
          id: 2,
          question: "Sa herët duhet të arrij për takimin tim?",
          answer:
            "Është në dorën tuaj se sa herët dëshironi të vini për takimin tuaj. Nëse doni të kurseni kohë, mund të vini pikërisht në kohën e caktuar. Megjithatë, ne rekomandojmë që të vini pak më herët në mënyrë që të shijoni një filxhan çaj ose kafe me ne, të relaksoheni në një nga karriget tona masazhi, ose thjesht të shijoni ambientin e qetë dhe muzikën qetësuese në zonën tonë të pritjes.",
        },
        {
          id: 3,
          question: "Çfarë nëse duhet të ndryshoj datën ose orën e takimit?",
          answer:
            "Megjithëse nuk rekomandojmë ndryshimin e datës ose orës së takimit në minutën e fundit, ne e kuptojmë që emergjencat mund të ndodhin në çdo kohë. Megjithatë, ju kërkojmë që të na informoni sa më shpejt të jetë e mundur nëse nuk mund të arrini në takimin tuaj. Ju lutem telefononi në +355 69 20 96 720 dhe ne do të jemi të lumtur të riplanifikojmë takimin tuaj. ",
        },
        {
          id: 4,
          question:
            "Sa kohë duhet për të përfunduar një procedurë implanti dentar?",
          answer:
            "Procesi zakonisht kërkon disa muaj, me fazën e parë që është vendosja e implantit dhe faza e shërimit që vijon. Restaurimi përfundimtar vendoset pasi implanti të integrohet me kockën.",
        },
        {
          id: 5,
          question:
            "A mund të kombinoj trajtime të shumta dentare në një vizitë?",
          answer:
            "Po, në varësi të trajtimeve dhe rastit tuaj specifik, shpesh mund të kombinojmë procedura për të minimizuar udhëtimet tuaja dhe për të optimizuar kohën tuaj.",
        },
        {
          id: 6,
          question: "A është në dispozicion qetësuesi për pacientët në ankth?",
          answer:
            "Po, ne ofrojmë mundësi qetësuese për pacientët që ndihen në ankth gjatë procedurave dentare për të siguruar një përvojë të rehatshme dhe pa stres.",
        },
        {
          id: 7,
          question:
            "A kam nevojë për vizë për të udhëtuar në Shqipëri për trajtim dentar?",
          answer:
            "Kërkesat për vizë varen nga vendi juaj i origjinës. Shumë vizitorë nga Europa nuk kërkojnë vizë për qëndrime të shkurtra. Ju lutemi kontrolloni kërkesat më të fundit ose na kontaktoni për ndihmë.",
        },
        {
          id: 8,
          question:
            "Sa larg është klinika nga aeroporti, dhe a ofroni transport?",
          answer:
            "Klinika jonë ndodhet në mënyrë të volitshme 17 km nga aeroporti, dhe ne sigurojmë transport falas për në aeroport dhe nga aeroporti për pacientët tanë ndërkombëtarë.",
        },
        {
          id: 9,
          question: "A mund të marr një konsultim para se të mbërrij?",
          answer:
            "Po, ne ofrojmë konsultime virtuale falas për pacientët ndërkombëtarë për të diskutuar nevojat tuaja dentare dhe për të ofruar një plan paraprak trajtimi para se të udhëtoni. Pasi të mbërrini në klinikë, ne japim një konsultim falas në person së bashku me çdo skanim 3D ose rreze X të nevojshme, duke siguruar një vlerësim të plotë pa kosto shtesë.",
        },
        {
          id: 10,
          question: "Çfarë kujdesi siguroni pas procedurës sime?",
          answer:
            "Ne japim udhëzime të hollësishme pas trajtimit, dhe ekipi ynë është gjithmonë në dispozicion për konsultime pasuese, qoftë në person, qoftë online, për të siguruar që shërimi juaj të shkojë pa probleme.",
        },
        {
          id: 11,
          question: "A do të më duhet të kthehem për vizita pasuese?",
          answer:
            "Për shumicën e trajtimeve, një ndjekje mund të bëhet praktikisht. Megjithatë, për disa procedura si implantet, ose Clear Aligners ne mund të rekomandojmë një ndjekje në person për të siguruar rezultate optimale.",
        },
      ],
    };
  },
  computed: {
    faqs() {
      return this.faqItems && this.faqItems.length > 0
        ? this.faqItems
        : this.defFaqs;
    },
  },
  mounted() {
    const accordionHeaders = this.$el.querySelectorAll(".accordion-header");

    accordionHeaders.forEach((header) => {
      header.addEventListener("click", function () {
        const isActive = this.classList.contains("active");

        accordionHeaders.forEach((hdr) => {
          if (hdr !== this) {
            hdr.classList.remove("active");
            hdr.parentElement.classList.remove("hoverBl");
            hdr.nextElementSibling.style.maxHeight = null;
          }
        });

        const activeItem = this.nextElementSibling;

        if (isActive) {
          this.classList.remove("active");
          this.parentElement.classList.remove("hoverBl");
          activeItem.style.maxHeight = null;
        } else {
          this.classList.add("active");
          this.parentElement.classList.add("hoverBl");
          activeItem.style.maxHeight = activeItem.scrollHeight + "px";
        }
      });
    });
  },
};
</script>

<style scoped>
.accordion h3 {
  color: #6c63ff;
  font-size: 18px;
  margin-bottom: 10px;
}

.accordion-item {
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  font-family: Gilroy !important;
}

.accordion-item:first-of-type {
  border-top: 1px solid #e5e5e5;
}
.accordion-header {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-family: Gilroy;
  font-weight: 400;
  border: none;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-header:after {
  content: "+";
  font-size: 18px;
  color: #c49754;
}

.accordion-header.active:after {
  content: "-";
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background-color: #c49754;
}
.accordion-content > p {
  padding: 20px;
  line-height: 2;
}

.hoverBl {
  border: 1.4px solid #c49754 !important;
}

#faq-segment > h3 {
  color: #c49754;
  margin-bottom: 10px;
  font-size: 24px;
}
</style>
