<template>
  <nav class="navbar">
    <div class="navbar-container">
      <!-- Logo Button -->
      <button @click="navigateToHomepage" class="navbar-logo">
        <img src="../assets/img/Logo/logoHMC.svg" alt="Logo" />
      </button>

      <!-- Language Switcher and Mobile Menu Button -->
      <div class="navbar-controls">
        <language-switcher-component class="language-switcher" />
        <button @click="toggleMobileMenu" class="navbar-mobile-toggle">
          <span v-if="!isMobileMenuOpen">☰</span>
          <span v-else>&times;</span>
        </button>
      </div>

      <!-- Desktop Menu -->
      <div class="navbar-menu">
        <template v-for="item in menuItems" :key="item.name">
          <router-link
            v-if="!item.isDropdown"
            :to="localizedPath(item.path)"
            class="navbar-item"
          >
            {{ item.name }}
          </router-link>
          <div v-else class="navbar-item navbar-dropdown">
            <button class="navbar-dropdown-toggle">{{ item.name }}</button>
            <div class="navbar-dropdown-content">
              <div class="navbar-dropdown-grid">
                <div
                  v-for="category in treatmentCategories"
                  :key="category.name"
                  class="navbar-dropdown-category"
                >
                  <h3>{{ category.name }}</h3>
                  <hr />
                  <ul>
                    <li
                      v-for="treatment in category.treatments"
                      :key="treatment.name"
                    >
                      <router-link :to="localizedPath(treatment.path)">{{
                        treatment.name
                      }}</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

      <!-- Appointment Button -->
      <button
        class="navbar-appointment"
        @click="$router.push(localizedPath('/contact'))"
      >
        Book Appointment
      </button>
    </div>

    <!-- Mobile Menu -->
    <div v-show="isMobileMenuOpen" class="navbar-mobile-menu">
      <template v-for="item in menuItems" :key="item.name">
        <router-link
          v-if="!item.isDropdown"
          :to="localizedPath(item.path)"
          class="navbar-mobile-item"
        >
          <i :class="item.icon"></i>
          {{ item.name }}
        </router-link>
        <div v-else class="navbar-mobile-dropdown">
          <button
            @click="toggleTreatmentsMenu"
            class="navbar-mobile-dropdown-toggle"
          >
            <i :class="item.icon"></i>
            {{ item.name }}
          </button>
          <div
            v-show="isTreatmentsMenuOpen"
            class="navbar-mobile-dropdown-content"
          >
            <div
              v-for="category in treatmentCategories"
              :key="category.name"
              class="navbar-mobile-category"
            >
              <h3>{{ category.name }}</h3>
              <ul>
                <li
                  v-for="treatment in category.treatments"
                  :key="treatment.name"
                >
                  <router-link :to="localizedPath(treatment.path)">{{
                    treatment.name
                  }}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>
      <button
        class="navbar-mobile-appointment"
        @click="$router.push(localizedPath('/contact'))"
      >
        Book Appointment
      </button>
    </div>
  </nav>
</template>

<script setup>
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import LanguageSwitcherComponent from "./LanguageSwitcherComponent.vue";
const router = useRouter();
const route = useRoute();

const isMobileMenuOpen = ref(false);
const isTreatmentsMenuOpen = ref(false);

const toggleMobileMenu = () => {
  isMobileMenuOpen.value = !isMobileMenuOpen.value;
};

const toggleTreatmentsMenu = () => {
  isTreatmentsMenuOpen.value = !isTreatmentsMenuOpen.value;
};

// Menu items
const menuItems = [
  { name: "Home", path: "/", icon: "fas fa-home" },
  { name: "Who We Are", path: "/who-we-are", icon: "fas fa-users" },
  { name: "Treatments", path: "#", isDropdown: true, icon: "fas fa-tooth" },
  {
    name: "Dental Tourism",
    path: "/dental-tourism",
    icon: "fas fa-plane-departure",
  },
  { name: "Before & After", path: "/before-after", icon: "fas fa-images" },
  { name: "Gallery", path: "/gallery", icon: "fas fa-camera" },
  { name: "Contact", path: "/contact", icon: "fas fa-envelope" },
];

// Treatment categories
const treatmentCategories = [
  {
    name: "General Treatments",
    treatments: [
      { name: "Examination", path: "/examination" },
      { name: "Teeth Cleaning", path: "/teeth-cleaning" },
      { name: "3D Scan", path: "/3d-scan" },
      { name: "Tooth Extraction", path: "/tooth-extraction" },
      { name: "Gum Treatments", path: "/gum-treatments" },
    ],
  },
  {
    name: "Restorative Treatments",
    treatments: [
      { name: "Composite Filling", path: "/composite-filling" },
      { name: "Root Canal Treatment", path: "/root-canal-treatment" },
    ],
  },
  {
    name: "Dental Implants",
    treatments: [
      { name: "Single Implant", path: "/single-implant" },
      {
        name: "Implant Supported Bridge",
        path: "/implant-supported-bridge",
      },
      { name: "All on 4", path: "/all-on-four" },
      { name: "All on 6", path: "/all-on-six" },
      { name: "Sinus Lift", path: "/sinus-lift" },
      { name: "Bone Graft", path: "/bone-graft" },
    ],
  },
  {
    name: "Prosthetic Treatments",
    treatments: [
      { name: "Zirconia Crowns", path: "/zirconia-crowns" },
      { name: "Porcelain-Metal Crowns", path: "/porcelain-metal-crowns" },
      { name: "Dental Veneers", path: "/dental-veneers" },
      { name: "Dentures", path: "/dentures" },
    ],
  },
  {
    name: "Aesthetic Treatments",
    treatments: [
      { name: "Hollywood Smile", path: "/hollywood-smile" },
      { name: "Teeth Whitening", path: "/teeth-whitening" },
      { name: "Fillers", path: "/fillers" },
      { name: "Botox", path: "/botox" },
      { name: "Mesotherapy", path: "/mesotherapy" },
    ],
  },
  {
    name: "Orthodontic Treatments",
    treatments: [
      { name: "Clear Aligners", path: "/clear-aligners" },
      { name: "Braces", path: "/braces" },
    ],
  },
];

// Helper function to localize paths
const localizedPath = (path) => {
  const langMatch = route.path.match(/^\/(en|al|it)/);
  const currentLang = langMatch ? langMatch[1] : "en";
  return currentLang === "en" ? path : `/${currentLang}${path}`;
};

// Navigate to the localized homepage
const navigateToHomepage = () => {
  const langMatch = route.path.match(/^\/(en|al|it)/);
  const currentLang = langMatch ? langMatch[1] : "en";
  const homepagePath = currentLang === "en" ? "/" : `/${currentLang}`;
  router.push(homepagePath);
};
</script>

<style scoped>
.navbar {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  max-width: 80%;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

.navbar-logo img {
  height: 60px;
  max-width: 100%;
}

.navbar-menu {
  display: none;
}

.navbar-item {
  color: #333;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: color 0.3s ease;
  font-weight: 600;
}

.navbar-item:hover {
  color: #c49754;
}

.navbar-dropdown {
  position: relative;
}

.navbar-dropdown-toggle {
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.navbar-dropdown-content {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  min-width: 1224px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  padding: 1rem;
  border-radius: 4px;
}

.navbar-dropdown:hover .navbar-dropdown-content {
  display: block;
}

.navbar-dropdown-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
}

.navbar-dropdown-category h3 {
  color: #c29757;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.navbar-dropdown-category hr {
  border: none;
  border-top: 1px solid #e0e0e0;
  margin-bottom: 0.5rem;
}

.navbar-dropdown-category ul {
  list-style-type: none;
  padding: 0;
}

.navbar-dropdown-category li {
  margin-bottom: 0.25rem;
}

.navbar-dropdown-category a {
  color: #333;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.navbar-dropdown-category a:hover {
  color: #c49754;
}

.navbar-menu {
  display: flex;
  align-items: center;
}

.navbar-mobile-toggle {
  display: none;
}

.navbar-appointment {
  display: block;
  background-color: #c49754;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-weight: 600;
}

.navbar-appointment:hover {
  background-color: #158c86;
}

.navbar-controls {
  display: none;
}

@media all and (max-width: 1200px) {
  .language-switcher {
    margin-right: 10px;
  }

  .navbar-controls {
    display: flex;
    z-index: 444;
  }

  .navbar-appointment {
    display: none;
  }

  .navbar-menu {
    display: none;
  }

  .navbar-logo img {
    height: 50px;
  }

  .navbar-container {
    max-width: 92%;
  }

  .navbar-menu {
    display: none;
  }

  .navbar-mobile-toggle {
    display: block;
  }

  .navbar-appointment {
    display: none;
  }

  .navbar-mobile-toggle {
    display: block;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .navbar-appointment {
    display: none;
  }

  .navbar-mobile-menu {
    background-color: #fff;
    padding: 1rem;
  }

  .navbar-mobile-item {
    display: flex;
    align-items: center;
    color: #333;
    text-decoration: none;
    padding: 0.5rem 0;
    border-bottom: 1px solid #e0e0e0;
  }

  .navbar-mobile-item i {
    margin-right: 0.5rem;
    min-width: 20px;
    text-align: center;
  }

  .navbar-mobile-dropdown-toggle {
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    color: #333;
    font-size: 1rem;
    padding: 0.5rem 0;
    cursor: pointer;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
  }

  .navbar-mobile-dropdown-toggle i {
    margin-right: 0.5rem;
    min-width: 20px;
    text-align: center;
  }

  .navbar-mobile-dropdown-content {
    padding-left: 1rem;
  }

  .navbar-mobile-category h3 {
    color: #c49754;
    margin: 1rem 0 0.5rem;
  }

  .navbar-mobile-category ul {
    list-style-type: none;
    padding: 0;
  }

  .navbar-mobile-category li {
    margin-bottom: 0.25rem;
  }

  .navbar-mobile-category a {
    color: #333;
    text-decoration: none;
    font-size: 0.9rem;
  }

  .navbar-mobile-appointment {
    display: block;
    width: 100%;
    background-color: #c49754;
    color: #fff;
    border: none;
    padding: 0.75rem;
    margin-top: 1rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }

  .navbar-mobile-appointment:hover {
    background-color: #158c86;
  }
}
</style>
