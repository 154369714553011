<template>
  <nav-bar-child></nav-bar-child>
  <nav-bar></nav-bar>

  <generic-image-holder-component
    header="Chi Siamo"
    subText="Impegnati nell'Eccellenza delle Cure Dentistiche e Estetiche"
  ></generic-image-holder-component>

  <div id="grid-container">
    <our-vision-component></our-vision-component>

    <div class="dental-clinic">
      <div class="clinic-image"></div>
      <div class="clinic-info">
        <h1 class="clinic-name">Su Health Medical Center</h1>
        <p class="clinic-description">
          Benvenuti a Health Medical Center, una clinica leader nel settore
          dentale ed estetico situata nel cuore di Tirana, Albania. Siamo
          specializzati nell’offrire trattamenti di alta qualità e a prezzi
          accessibili a pazienti provenienti da tutto il mondo, combinando cure
          esperte con un approccio personalizzato e orientato al paziente.
        </p>
        <p class="clinic-description">
          La nostra clinica offre le ultime tecnologie dentali ed estetiche, con
          un team di specialisti formati a livello internazionale, dedicati a
          garantire risultati eccezionali. Che tu stia cercando impianti
          dentali, odontoiatria estetica o un cambio completo del sorriso,
          personalizziamo ogni trattamento per adattarlo alle tue esigenze
          individuali.
        </p>

        <div class="clinic-stats">
          <div class="stat">
            <h3>20+</h3>
            <p>Anni di Esperienza</p>
          </div>
          <div class="stat">
            <h3>5</h3>
            <p>Dentisti Specialisti</p>
          </div>
          <div class="stat">
            <h3>20+</h3>
            <p>Membri del Personale di Supporto</p>
          </div>
        </div>
        <p class="clinic-cta">
          Trasforma il tuo sorriso con Health Medical Center a Tirana!
          <a href="/it/contact" class="contact-us"> Contattaci oggi</a> stesso
          per prenotare la tua consulenza e fare il primo passo verso un sorriso
          più sano e sicuro. Siamo qui per offrirti cure esperte e trattamenti
          personalizzati per aiutarti a realizzare il sorriso che hai sempre
          desiderato.
        </p>
        <ul class="clinic-features">
          <li>Cure Dentali Complete</li>
          <li>Soluzioni Estetiche all’Avanguardia</li>
          <li>Professionisti Dentali Esperti</li>
          <li>Piani di Trattamento Accessibili</li>
          <li>Tecnologia Dentale Moderna</li>
          <li>Cura Personalizzata Pre- e Post-Trattamento</li>
        </ul>
      </div>
    </div>

    <div class="what-makes-us-different">
      <h2>Cosa ci rende diversi?</h2>
      <p class="intro">
        Presso Health Medical Center, crediamo che le cure dentali di alto
        livello vadano oltre l’eccellenza clinica. Ci concentriamo sull’offrire
        un’esperienza completa al paziente, che prioritizzi comfort, semplicità
        e soddisfazione in ogni fase del trattamento. Ecco come lo rendiamo
        possibile:
      </p>
      <div class="card-container">
        <div v-for="(item, index) in differentiators" :key="index" class="card">
          <div
            class="card-image"
            :style="{ backgroundImage: `url(${item.image})` }"
          ></div>
          <div class="card-content">
            <div class="card-header">
              <h3>{{ item.title }}</h3>
            </div>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="what-makes-us-different">
      <h2>La Nostra Gamma di Trattamenti Specializzati</h2>
      <p class="intro">
        Presso la nostra clinica, uniamo competenza, tecnologie all’avanguardia
        e impegno per offrire un’ampia gamma di trattamenti dentali ed estetici,
        tra cui:
      </p>
      <div class="flip-card-container">
        <flip-card-component
          header-title="Implantologia Dentale"
          header-subtitle="Soluzioni permanenti per denti mancanti con tecniche avanzate."
          header-img="flip-card/implant.jpg"
        ></flip-card-component>

        <flip-card-component
          header-title="Odontoiatria Estetica"
          header-subtitle="Migliora il tuo sorriso con faccette, sbiancamento dentale e altri trattamenti estetici."
          header-img="flip-card/cosmetic.jpg"
        ></flip-card-component>

        <flip-card-component
          header-title="Ortodonzia"
          header-subtitle="Allinea i tuoi denti con allineatori trasparenti o apparecchi tradizionali."
          header-img="flip-card/orthodontics.jpg"
        ></flip-card-component>

        <flip-card-component
          header-title="Odontoiatria Restaurativa"
          header-subtitle="Corone, ponti e riabilitazioni complete per ripristinare funzionalità ed estetica."
          header-img="flip-card/restorative.jpg"
        ></flip-card-component>

        <flip-card-component
          header-title="Trattamenti Parodontali"
          header-subtitle="Cura completa delle gengive e pulizie profonde per una salute orale ottimale."
          header-img="flip-card/perio.jpg"
        ></flip-card-component>

        <flip-card-component
          header-title="Trattamenti Estetici"
          header-subtitle="Botox, filler e trapianti di capelli per migliorare la tua sicurezza e il tuo aspetto."
          header-img="flip-card/aesthetic.jpg"
        ></flip-card-component>
      </div>
      <div class="cta">
        Pronto a iniziare il tuo percorso dentale con noi?<br />
        Contattaci oggi per una consulenza gratuita e lasciaci aiutarti a
        ottenere il sorriso dei tuoi sogni.
        <br />
        <button @click="$router.push({ path: '/it/contact' })">
          Cambia il tuo sorriso
        </button>
      </div>
    </div>
  </div>
  <footer-component></footer-component>
</template>

<script setup>
import NavBar from "@/components/it/NavBarIT.vue";
import NavBarChild from "@/components/it/NavBarChildIT.vue";
import OurVisionComponent from "@/components/it/OurVisionComponentIT.vue";
import GenericImageHolderComponent from "@/components/it/GenericImageHolderComponentIT.vue";
import FooterComponent from "@/components/it/FooterComponentIT.vue";
import FlipCardComponent from "@/components/FlipCardComponent.vue";

const differentiators = [
  {
    title: "Piani di Trattamento Personalizzati:",
    description:
      "Ogni paziente è unico, così come le sue esigenze dentali. Per questo i nostri dentisti creano piani di trattamento personalizzati, progettati specificamente per i tuoi obiettivi e preferenze. Valutando attentamente la tua salute dentale e discutendo le tue aspettative, garantiamo che riceverai la migliore cura possibile, su misura per te.",
    image: require(`@/assets/img/office/12copy.webp`),
  },
  {
    title: "Competenza e Tecnologia di Livello Mondiale:",
    description:
      "La nostra clinica combina l’esperienza di dentisti qualificati con tecnologie all’avanguardia. Dalla diagnostica precisa con TAC 3D a tecniche minimamente invasive, investiamo nelle innovazioni più recenti per ottenere risultati ottimali e assicurare un’esperienza fluida per ogni paziente.",
    image: require(`@/assets/img/office/12copy.webp`),
  },
  {
    title: "Supporto Completo e Follow-Up:",
    description:
      "Il tuo percorso con noi non finisce con la procedura. Forniamo un supporto completo post-trattamento e piani di assistenza personalizzati per garantire una guarigione fluida e confortevole. Il nostro team dedicato è sempre disponibile per rispondere alle tue domande e guidarti nel processo di assistenza post-operatoria.",
    image: require(`@/assets/img/office/12copy.webp`),
  },
  {
    title: "Comfort e Convenienza a Ogni Passo:",
    description:
      "Dal momento in cui arrivi, ci concentriamo nel rendere la tua esperienza priva di stress. Offriamo servizi di trasporto di lusso, assistenza per i viaggi e un’atmosfera accogliente in clinica. Che si tratti di aiuto con i piani di viaggio o di fornire comodità durante la visita, diamo priorità al tuo comfort in ogni fase.",
    image: require(`@/assets/img/office/12copy.webp`),
  },
];
</script>

<style scoped>
:root {
  --primary-color: #4a90e2;
  --text-color: #333;
  --text-color-light: #666;
  --background-light: #ffffff;
  --background-dark: #f0f0f0;
}

#grid-container {
  display: grid;
  max-width: 80%;
  margin: auto;
  margin-top: 44px;
}

.contact-us {
  text-decoration: underline;
  color: #c49754;
  font-weight: 600;
}

.dental-clinic {
  display: flex;
  max-width: 100%;
  margin: 0 auto;
  background: linear-gradient(
    135deg,
    var(--background-light) 50%,
    var(--background-dark) 50%
  );
  border-radius: 15px !important;
}

.flip-card-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 30px;
  row-gap: 30px;
}

.clinic-image {
  flex: 1;
  background-image: url("../../assets/img/office/office_0.webp");
  background-size: cover;
  background-position: center;
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.cta {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-top: 44px;
}

.cta button {
  background-color: #c49754;
  padding: 5px 30px;
  color: #fff;
  border-radius: 10px;
}
.cta button:hover {
  background-color: #fff;
  border: 1px solid #c49754;
  padding: 5px 30px;
  color: #000;
  border-radius: 10px;
}

.clinic-info {
  flex: 1;
  padding: 2rem;
}

.who-we-are {
  color: var(--text-color-light);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  text-align: left;
  font-weight: 500;
}

.clinic-name {
  font-size: 2rem;
  color: var(--text-color);
  margin-bottom: 1rem;
}

.clinic-description,
.clinic-mission {
  color: var(--text-color-light);
  margin-bottom: 1rem;
  line-height: 1.6;
}

.clinic-stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.stat {
  text-align: center;
}

.stat h3 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.stat p {
  color: var(--text-color-light);
}

.clinic-cta {
  font-style: italic;
  color: var(--text-color-light);
  margin-bottom: 1rem;
}

.clinic-features {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
}

.clinic-features li {
  color: var(--text-color-light);
  display: flex;
  align-items: center;
}

.clinic-features li::before {
  content: "✓";
  color: var(--primary-color);
  margin-right: 0.5rem;
}

@media all and (max-width: 1200px) {
  #grid-container {
    max-width: 92%;
  }

  .card-container {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .flip-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .dental-clinic {
    flex-direction: column;
  }

  .clinic-image {
    height: 300px;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
  }

  .clinic-info {
    padding: 1rem;
  }

  .clinic-name {
    font-size: 1.5rem;
  }

  .clinic-stats {
    flex-direction: column;
  }

  .stat {
    margin-bottom: 1rem;
  }

  .clinic-features {
    grid-template-columns: 1fr;
  }

  .card-container {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .flip-card-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.what-makes-us-different {
  font-family: Arial, sans-serif;
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
}

h2 {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 1rem;
}

.intro {
  font-size: 1.1rem;
  color: #666;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 2rem;
  line-height: 1.6;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.card {
  background-color: #f8f8f8;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card-image {
  width: 120px;
  background-size: cover;
  background-position: center;
  /* clip-path: polygon(0 0, 100% 0, 0 100%); */
  background-position-x: 48%;
}

.card-content {
  padding: 1.5rem;
  flex: 1;
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
  fill: #2c3e50;
}

.card h3 {
  font-size: 1.3rem;
  color: #2c3e50;
  margin: 0;
}

.card p {
  font-size: 1rem;
  color: #34495e;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .what-makes-us-different {
    padding: 1rem;
  }

  h2 {
    font-size: 2rem;
  }

  .intro {
    font-size: 1rem;
  }

  .card-container {
    grid-template-columns: 1fr;
  }

  .card {
    flex-direction: column;
  }

  .card-image {
    width: 100%;
    height: 120px;
    clip-path: polygon(0 0, 100% 0, 0 100%);
  }
}
</style>
