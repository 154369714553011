<template>
  <nav-bar-child></nav-bar-child>
  <nav-bar></nav-bar>

  <generic-image-holder-component
    header="Kush Jemi"
    subText="Të angazhuar për Ekselencën në Kujdesin Dentar dhe Estetik"
  ></generic-image-holder-component>

  <div id="grid-container">
    <our-vision-component></our-vision-component>

    <div class="dental-clinic">
      <div class="clinic-image"></div>
      <div class="clinic-info">
        <h1 class="clinic-name">Rreth Health Medical Center</h1>
        <p class="clinic-description">
          Mirë se vini në Health Medical Center, një klinikë udhëheqëse dentare
          dhe estetike e vendosur në zemrën e Tiranës, Shqipëri. Ne
          specializohemi në ofrimin e trajtimeve të cilësisë së lartë dhe të
          përballueshme për pacientët nga e gjithë bota, duke kombinuar kujdesin
          ekspert me një qasje të fokusuar në pacient.
        </p>
        <p class="clinic-description">
          Klinika jonë ofron teknologjinë më të fundit në stomatologji dhe
          estetikë, me një ekip specialistësh të trajnuar ndërkombëtarisht të
          përkushtuar për të ofruar rezultate të jashtëzakonshme. Qoftë nëse po
          kërkoni implante dentare, stomatologji kozmetike, ose një transformim
          të plotë të buzëqeshjes, ne e përshtatim çdo trajtim për t'i
          përmbushur nevojat tuaja individuale.
        </p>

        <div class="clinic-stats">
          <div class="stat">
            <h3>20+</h3>
            <p>Vite Eksperience</p>
          </div>
          <div class="stat">
            <h3>5</h3>
            <p>Dentistë Specialistë</p>
          </div>
          <div class="stat">
            <h3>20+</h3>
            <p>Staf Mbështetës</p>
          </div>
        </div>
        <p class="clinic-cta">
          Transformoni buzëqeshjen tuaj me Health Medical Center në Tiranë!
          <a href="/al/contact" class="contact-us"> Kontaktoni sot </a>për të
          programuar konsultimin tuaj dhe bëni hapin e parë drejt një veteje më
          të shëndetshme dhe më me besim. Ne jemi këtu për t'ju ofruar kujdes
          ekspert dhe trajtim të personalizuar për t'ju ndihmuar të arrini
          buzëqeshjen që keni ëndërruar gjithmonë.
        </p>
        <ul class="clinic-features">
          <li>Kujdes Dentar i Plotë</li>
          <li>Zgjidhje Estetike të Avancuara</li>
          <li>Profesionist Dentar të Kualifikuar</li>
          <li>Planifikime Trajtimi të Përballueshme</li>
          <li>Teknologji Moderne Dentare</li>
          <li>Kujdes i Personalizuar Para dhe Pas Trajtimit</li>
        </ul>
      </div>
    </div>

    <div class="what-makes-us-different">
      <h2>Çfarë Na Bën Ne të Ndryshëm</h2>
      <p class="intro">
        Në Health Medical Center, ne besojmë se kujdesi dentar i nivelit të
        lartë tejkalon ekselencën klinike. Ne përqendrohemi në ofrimin e një
        përvoje të plotë për pacientin që prioritizon komoditetin, lehtësinë dhe
        kënaqësinë në çdo fazë të trajtimit tuaj. Ja si e realizojmë këtë:
      </p>
      <div class="card-container">
        <div v-for="(item, index) in differentiators" :key="index" class="card">
          <div
            class="card-image"
            :style="{ backgroundImage: `url(${item.image})` }"
          ></div>
          <div class="card-content">
            <div class="card-header">
              <h3>{{ item.title }}</h3>
            </div>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="what-makes-us-different">
      <h2>Gama Jonë e Trajtimeve të Specializuara</h2>
      <p class="intro">
        Në klinikën tonë, ne kombinojmë ekspertizën, teknologjinë më moderne dhe
        përkushtimin për ekselencë për të ofruar një gamë të plotë trajtimesh
        dentar dhe estetik, duke përfshirë:
      </p>
      <div class="flip-card-container">
        <flip-card-component
          header-title="Implante Dentare"
          header-subtitle="Zgjidhje të përhershme për mungesën e dhëmbëve me teknika të avancuara implantimi."
          header-img="flip-card/implant.jpg"
          img-alt="Ilustrim i implanteve dentare"
        ></flip-card-component>

        <flip-card-component
          header-title="Dentistri Kozmetike"
          header-subtitle="Transformime të buzëqeshjes përmes fasetave, zbardhimit të dhëmbëve dhe më shumë."
          header-img="flip-card/cosmetic.jpg"
          img-alt="Imazh që tregon rezultatet e dentistrisë kozmetike"
        ></flip-card-component>

        <flip-card-component
          header-title="Ortodoncioni"
          header-subtitle="Drejtoni dhëmbët tuaj me aligner-a transparentë ose aparate tradicionale."
          header-img="flip-card/orthodontics.jpg"
          img-alt="Foto e aparateve ortodontike"
        ></flip-card-component>

        <flip-card-component
          header-title="Dentistri Restauruese"
          header-subtitle="Kurora, ura dhe rehabilitime të plota orale për të rikthyer funksionin dhe bukurinë."
          header-img="flip-card/restorative.jpg"
          img-alt="Imazh që tregon punime dentare restauruese"
        ></flip-card-component>

        <flip-card-component
          header-title="Trajtimet Periodontale"
          header-subtitle="Kujdes gjithëpërfshirës për mishrat e dhëmbëve dhe pastrime të thella për shëndet optimal oral."
          header-img="flip-card/perio.jpg"
          img-alt="Përfaqësim vizual i trajtimeve periodontale"
        ></flip-card-component>

        <flip-card-component
          header-title="Trajtimet Estetike"
          header-subtitle="Përfshirë Botox, mbushës dermalë dhe restaurim të flokëve për të rritur vetëbesimin dhe pamjen tuaj."
          header-img="flip-card/aesthetic.jpg"
          img-alt="Ilustrim i trajtimeve estetike"
        ></flip-card-component>
      </div>

      <div class="cta">
        Gati për të filluar udhëtimin tuaj dentar me ne?<br />
        Kontaktoni sot për një konsultim falas dhe lejoni që ne t'ju ndihmojmë
        të arrini buzëqeshjen e ëndrrave tuaja.
        <br />
        <button @click="$router.push({ path: '/al/contact' })">
          Ndryshoni Buzëqeshjen Tuaj
        </button>
      </div>
    </div>
  </div>
  <footer-component></footer-component>
</template>

<script setup>
import NavBar from "@/components/al/NavBarAL.vue";
import NavBarChild from "@/components/al/NavBarChildAL.vue";
import OurVisionComponent from "@/components/al/OurVisionComponentAL.vue";
import GenericImageHolderComponent from "@/components/al/GenericImageHolderComponentAL.vue";
import FooterComponent from "@/components/al/FooterComponentAL.vue";
import FlipCardComponent from "@/components/al/FlipCardComponentAL.vue";

const differentiators = [
  {
    title: "Plani i trajtimeve të personalizuara:",
    description:
      "Ne e kuptojmë që çdo pacient është unik dhe ashtu janë edhe nevojat e tyre dentare. Prandaj, dentistët tanë krijojnë plane trajtimi të personalizuara të përshtatura posaçërisht me qëllimet dhe preferencat tuaja. Duke vlerësuar në detaje shëndetin tuaj dentar dhe duke diskutuar pritshmëritë tuaja, ne sigurojmë që të merrni kujdesin më të mirë të mundshëm të krijuar vetëm për ju.",
    image: require(`@/assets/img/office/12copy.webp`),
  },
  {
    title: "Ekspertizë dhe teknologji e klasit botëror:",
    description:
      "Klinika jonë kombinon ekspertizën e dentistëve me përvojë dhe teknologjinë më të avancuar. Nga imazheria 3D e avancuar për diagnostikim të saktë deri te teknikat minimalisht invazive, ne investojmë në risitë më të fundit për të arritur rezultate optimale dhe për të siguruar një përvojë të qetë për çdo pacient.",
    image: require(`@/assets/img/office/12copy.webp`),
  },
  {
    title: "Mbështetje dhe ndjekje gjithëpërfshirëse:",
    description:
      "Udhëtimi juaj me ne nuk përfundon me procedurën. Ne ofrojmë mbështetje të plotë pas-trajtimit dhe plane të personalizuara për kujdesin pasues për të siguruar që shërimi juaj të jetë i qetë dhe i rehatshëm. Ekipi ynë i përkushtuar është gjithmonë i disponueshëm për të përgjigjur pyetjeve dhe për t'ju udhëhequr gjatë procesit të kujdesit pasues.",
    image: require(`@/assets/img/office/12copy.webp`),
  },
  {
    title: "Komoditet dhe lehtësi në çdo hap:",
    description:
      "Që nga momenti kur mbërrini, ne fokusohemi në sigurimin e një përvoje pa stres. Ne ofrojmë shërbime transporti luksoze, ndihmë me aranzhimet e udhëtimit dhe një atmosferë të ngrohtë e mikpritëse në klinikë. Qoftë ndihma për planet e udhëtimit apo sigurimi i komoditeteve gjatë vizitës suaj, ne i japim përparësi rehatisë tuaj në çdo fazë.",
    image: require(`@/assets/img/office/12copy.webp`),
  },
];
</script>

<style scoped>
:root {
  --primary-color: #4a90e2;
  --text-color: #333;
  --text-color-light: #666;
  --background-light: #ffffff;
  --background-dark: #f0f0f0;
}

#grid-container {
  display: grid;
  max-width: 80%;
  margin: auto;
  margin-top: 44px;
}

.contact-us {
  text-decoration: underline;
  color: #c49754;
  font-weight: 600;
}

.dental-clinic {
  display: flex;
  max-width: 100%;
  margin: 0 auto;
  background: linear-gradient(
    135deg,
    var(--background-light) 50%,
    var(--background-dark) 50%
  );
  border-radius: 15px !important;
}

.flip-card-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 30px;
  row-gap: 30px;
}

.clinic-image {
  flex: 1;
  background-image: url("../../assets/img/office/office_0.webp");
  background-size: cover;
  background-position: center;
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.cta {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-top: 44px;
}

.cta button {
  background-color: #c49754;
  padding: 5px 30px;
  color: #fff;
  border-radius: 10px;
}
.cta button:hover {
  background-color: #fff;
  border: 1px solid #c49754;
  padding: 5px 30px;
  color: #000;
  border-radius: 10px;
}

.clinic-info {
  flex: 1;
  padding: 2rem;
}

.who-we-are {
  color: var(--text-color-light);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  text-align: left;
  font-weight: 500;
}

.clinic-name {
  font-size: 2rem;
  color: var(--text-color);
  margin-bottom: 1rem;
}

.clinic-description,
.clinic-mission {
  color: var(--text-color-light);
  margin-bottom: 1rem;
  line-height: 1.6;
}

.clinic-stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.stat {
  text-align: center;
}

.stat h3 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.stat p {
  color: var(--text-color-light);
}

.clinic-cta {
  font-style: italic;
  color: var(--text-color-light);
  margin-bottom: 1rem;
}

.clinic-features {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
}

.clinic-features li {
  color: var(--text-color-light);
  display: flex;
  align-items: center;
}

.clinic-features li::before {
  content: "✓";
  color: var(--primary-color);
  margin-right: 0.5rem;
}

@media all and (max-width: 1200px) {
  #grid-container {
    max-width: 92%;
  }

  .card-container {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .flip-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .dental-clinic {
    flex-direction: column;
  }

  .clinic-image {
    height: 300px;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
  }

  .clinic-info {
    padding: 1rem;
  }

  .clinic-name {
    font-size: 1.5rem;
  }

  .clinic-stats {
    flex-direction: column;
  }

  .stat {
    margin-bottom: 1rem;
  }

  .clinic-features {
    grid-template-columns: 1fr;
  }

  .card-container {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .flip-card-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.what-makes-us-different {
  font-family: Arial, sans-serif;
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
}

h2 {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 1rem;
}

.intro {
  font-size: 1.1rem;
  color: #666;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 2rem;
  line-height: 1.6;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.card {
  background-color: #f8f8f8;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card-image {
  width: 120px;
  background-size: cover;
  background-position: center;
  /* clip-path: polygon(0 0, 100% 0, 0 100%); */
  background-position-x: 48%;
}

.card-content {
  padding: 1.5rem;
  flex: 1;
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
  fill: #2c3e50;
}

.card h3 {
  font-size: 1.3rem;
  color: #2c3e50;
  margin: 0;
}

.card p {
  font-size: 1rem;
  color: #34495e;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .what-makes-us-different {
    padding: 1rem;
  }

  h2 {
    font-size: 2rem;
  }

  .intro {
    font-size: 1rem;
  }

  .card-container {
    grid-template-columns: 1fr;
  }

  .card {
    flex-direction: column;
  }

  .card-image {
    width: 100%;
    height: 120px;
    clip-path: polygon(0 0, 100% 0, 0 100%);
  }
}
</style>
