<template>
  <!-- Contenedor principal de la línea de tiempo -->
  <h2>Tu Viaje Dental: Guía Paso a Paso</h2>
  <div class="timeline-container">
    <!-- Punto de línea de tiempo individual -->
    <div class="timeline-point">
      <i class="fa-solid fa-phone-volume"></i>
      <div class="popup">
        <div class="popup-number">1</div>
        <div class="popup-details">
          <div class="popup-title">Consulta Inicial</div>
          Comenzamos contactándote por teléfono o correo electrónico. Durante
          esta conversación, hablaremos sobre tus inquietudes dentales y podrás
          enviarnos tu radiografía dental. Esto nos ayuda a entender mejor tu
          situación y preparar las opciones de tratamiento adecuadas para ti.
        </div>
      </div>
    </div>

    <!-- Punto de línea de tiempo individual -->
    <div class="timeline-point">
      <i class="fa-solid fa-file-invoice"></i>

      <div class="popup">
        <div class="popup-number">2</div>
        <div class="popup-details">
          <div class="popup-title">
            Plan de Tratamiento y Estimación de Costos
          </div>
          Nuestro dentista revisa tus radiografías y los detalles que has
          proporcionado para crear un plan de tratamiento personalizado. También
          recibirás una estimación de los costos de tu tratamiento. El plan es
          flexible y se puede actualizar según tus necesidades o preferencias.
        </div>
      </div>
    </div>

    <!-- Punto de línea de tiempo individual -->
    <div class="timeline-point">
      <i class="fa-solid fa-plane"></i>
      <div class="popup">
        <div class="popup-number">3</div>
        <div class="popup-details">
          <div class="popup-title">Arreglos de Viaje</div>
          ¡Hacemos tu viaje más fácil! Dependiendo del precio final de tu
          tratamiento, podemos cubrir tu billete de avión y la estancia en el
          hotel. Independientemente de eso, siempre proporcionamos transporte de
          lujo gratuito desde y hacia el aeropuerto y asistimos con todos los
          arreglos de viaje para garantizar una experiencia fluida.
        </div>
      </div>
    </div>

    <!-- Punto de línea de tiempo individual -->
    <div class="timeline-point">
      <i class="fa-regular fa-calendar-check"></i>
      <div class="popup">
        <div class="popup-number">4</div>
        <div class="popup-details">
          <div class="popup-title">Llegada y Primera Cita</div>
          Cuando llegues, comenzaremos con una consulta gratuita, un escaneo 3D
          gratuito y una limpieza gratuita. Te reunirás con nuestros dentistas
          profesionales, y si deseas realizar cambios en el plan de tratamiento,
          discutiremos la cotización actualizada contigo.
        </div>
      </div>
    </div>

    <!-- Punto de línea de tiempo individual -->
    <div class="timeline-point">
      <i class="fa-solid fa-tooth"></i>
      <div class="popup">
        <div class="popup-number">5</div>
        <div class="popup-details">
          <div class="popup-title">Procedimiento y Recuperación</div>
          Tu tratamiento será realizado por nuestros dentistas expertos,
          asegurando la más alta calidad en el cuidado. Después del
          procedimiento, te proporcionaremos instrucciones detalladas de
          recuperación y estaremos disponibles para apoyarte durante tu proceso
          de curación para asegurarnos de que todo salga bien.
        </div>
      </div>
    </div>

    <!-- Punto de línea de tiempo individual -->
    <div class="timeline-point">
      <i class="fa-solid fa-hospital-user"></i>
      <div class="popup">
        <div class="popup-number">6</div>
        <div class="popup-details">
          <div class="popup-title">Seguimiento y Cuidado Posterior</div>
          Nos mantenemos en contacto contigo después de tu tratamiento para
          asegurarnos de que todo esté sanando como se esperaba. Para ciertos
          procedimientos, como los implantes, necesitarás regresar para una
          visita de seguimiento después de 3-6 meses. Nuestro equipo te guiará a
          través de todos los pasos necesarios para el cuidado posterior y se
          asegurará de que estés satisfecho con los resultados.
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Global styles */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h2 {
  font-size: 44px;
  text-align: center;
  font-weight: 800;
  margin-bottom: 20px;
}

/* Body styling */
body {
  background: #212529;
  height: 100vh;
  display: grid;
  place-items: center;
  font-family: "Gilroy", sans-serif;
}

/* Timeline container styling */
.timeline-container {
  display: flex;
  gap: 10%;
  width: 100%;
  height: 134px;
  justify-content: center;
  font-weight: 900;
  font-size: 67px;
  margin-top: 260px;
  margin-bottom: 300px;
}

/* Individual timeline point styling */
.timeline-point {
  transition-duration: 0.5s;
  border-radius: 50%;
  background: linear-gradient(145deg, #1e2125, #23282c);
  box-shadow: 13.4px 13.4px 40.2px #1c1f23, -13.4px -13.4px 40.2px #262b2f;
  display: grid;
  place-items: center;
  width: 134px;
  position: relative;
  color: var(--color);
}

.timeline-point::before {
  content: "";
  width: 200px;
  height: 200px;
  z-index: -1;
  border-radius: 50%;
  position: absolute;
  background: conic-gradient(
    var(--color) var(--angle),
    transparent 0deg 360deg
  );
  animation: rotateBorder 1s linear var(--delay) forwards;
}

.timeline-point:nth-child(odd)::before {
  transform: rotate(-90deg);
}

.timeline-point:nth-child(even)::before {
  transform: rotate(90deg) scaleY(-1);
}

/* Custom property for angle */
@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

/* Common styling for timeline points */
.timeline-point:nth-child(1) {
  --color: #c1121f;
  --delay: 0s;
}

.timeline-point:nth-child(2) {
  --color: #ffc300;
  --delay: 1s;
}

.timeline-point:nth-child(3) {
  --color: #2ec4b6;
  --delay: 2s;
}

.timeline-point:nth-child(4) {
  --color: #6536ff;
  --delay: 3s;
}

.timeline-point:nth-child(5) {
  --color: #3a86ff;
  --delay: 4s;
}
.timeline-point:nth-child(6) {
  --color: #95e207;
  --delay: 5s;
}

/* Popup styling */
.popup {
  width: 444px;
  height: auto;
  max-height: 0;
  background-color: var(--color);
  display: grid;
  grid-template-columns: 16% 84%;
  position: absolute;
  color: white;
  border-radius: 30px;
  box-shadow: 6.7px 6.7px 21.4px #17191d, -6.7px -6.7px 21.4px #212529;

  transform-origin: bottom bottom;
  animation: expandPopup 0.5s linear calc(var(--delay) + 0.5s) forwards;
}

.timeline-point:nth-child(odd) .popup {
  bottom: 221.1px;
}

.timeline-point:nth-child(even) .popup {
  top: 221.1px;
}

/* Popup number styling */
.popup-number {
  grid-row: span 2;
  display: grid;
  font-size: 2rem;
  font-weight: bold;
  place-items: center;
  cursor: auto;
  animation: fadeIn 0.5s linear calc(var(--delay) + 0.7s) forwards;
  opacity: 0;
  height: 100%;
}

/* Popup title styling */
.popup-title {
  color: var(--color);
  padding-bottom: 6.7px;
  font-size: 20.1px;
  font-weight: 900;
}

/* Popup details styling */
.popup-details {
  padding: 20.1px;
  background-color: #1c1f23;
  border-radius: 30px;
  opacity: 0;
  font-weight: 500;
  user-select: none;
  cursor: auto;
  overflow: hidden;
  font-size: 0.8rem;
  text-align: justify;
  background: linear-gradient(145deg, #16181b, #22262b);
  margin: 3.35px;
  animation: fadeIn 0.5s linear calc(var(--delay) + 0.7s) forwards;
}

.popup::before {
  content: "";
  width: 6.7px;
  height: 0;
  border-radius: 20px;
  background-color: var(--color);
  position: absolute;
  left: 50%;
  top: -41.9px;
  display: flex;
  animation: drawLine 0.5s linear var(--delay) forwards;
}

.timeline-point:nth-child(odd) .popup:before {
  top: calc(100% + 47px);
  transform: rotateX(180deg);
  transform-origin: top;
}

/* Hover effect for timeline points */
.timeline-point:hover {
  background: linear-gradient(145deg, var(--color), #1e2125);
  color: white;
}

/* Animation for border rotation */
@keyframes rotateBorder {
  from {
    --angle: 0deg;
  }

  to {
    --angle: 180deg;
  }
}

/* Animation for popup expansion */
@keyframes expandPopup {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 200px;
  }
}

/* Animation for line drawing */
@keyframes drawLine {
  0% {
    height: 0%;
    opacity: 0;
  }

  100% {
    height: 33.5px;
    opacity: 1;
  }
}

/* Animation for fade-in effect */
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@media all and (max-width: 1200px) {
  body {
    height: auto;
    display: block;
  }
  .timeline-container {
    flex-direction: column;
    height: auto;
    width: 100%;
    padding: 100px 0px;
    margin-top: 80px;
    margin-bottom: 144px;
  }

  .timeline-point {
    height: 134px;
    flex-shrink: 0;
    left: -33%;
    margin: 0px auto;
    position: relative;
  }

  .timeline-point:nth-child(odd)::before {
    transform: rotate(0deg);
  }

  .timeline-point:nth-child(even)::before {
    transform: rotate(0deg) scaleX(-1);
  }

  .timeline-point .popup:before {
    display: none;
  }

  .timeline-point:nth-child(odd) .popup {
    bottom: auto;
    right: -375%;
  }

  .timeline-point:nth-child(even) .popup {
    top: auto;
    right: -375%;
  }
}

@media all and (max-width: 768px) {
  .timeline-point:nth-child(odd) .popup {
    bottom: -170%;
    right: 0;
  }

  .timeline-point:nth-child(even) .popup {
    bottom: -170%;
    right: 0;
  }

  .timeline-point {
    left: 40%;
  }

  h2 {
    font-size: 24px;
  }
}
</style>
