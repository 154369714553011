<template>
  <div class="container">
    <div class="card">
      <h2 class="card-title">
        Why Choose Health Medical Center for Your Dental Treatment Abroad?
      </h2>
      <p class="card-description">
        Our experienced team of internationally trained dental professionals
        ensures you receive the best care possible. We use state-of-the-art
        technology and offer treatments at a fraction of the cost compared to
        many countries in Europe and US.
      </p>
      <ul class="card-list">
        <li>
          Experienced, international dental specialists (with credentials and
          international recognition)
        </li>
        <li>Advanced technology and modern facilities</li>
        <li>
          Affordable pricing compared to dental services in other countries
        </li>
        <li>Accreditation and certifications</li>
        <li>Multilingual staff for easy communication</li>
      </ul>
    </div>
    <div class="card">
      <h2 class="card-title">Affordable Dental Care: Save More with HMC</h2>
      <p class="card-description">
        With up to 70% savings on dental treatments compared to many Western
        countries, HMC allows you to receive top-notch care while keeping more
        of your hard-earned money
      </p>
    </div>
    <div class="card">
      <h2 class="card-title">
        We Make Your Trip Easy: Travel and Stay Assistance
      </h2>
      <p class="card-description">
        We help make your dental tourism experience as convenient as possible.
        From airport transfers to hotel accommodations, our team will assist you
        in every step of your journey.
      </p>
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.card:nth-child(1) {
  grid-row: 1 / 3;
  grid-column: 1/ 2;
}

.card:nth-child(2) {
  grid-row: 1;
  grid-column: 2/ 3;
}

.card:nth-child(3) {
  grid-row: 2;
  grid-column: 2/ 2;
}

.card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;

  font-family: Arial, sans-serif;
}

.card-title {
  color: #2c3e50;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.card-description {
  color: #34495e;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.card-list {
  list-style-type: none;
  padding-left: 0;
}

.card-list li {
  color: #34495e;
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
  padding-left: 1.5rem;
  position: relative;
}

.card-list li::before {
  content: "✓";
  color: #3498db;
  position: absolute;
  left: 0;
  font-weight: bold;
}

@media all and (max-width: 768px) {
  .card {
    margin: 1rem;
    padding: 1.5rem;
  }

  .card-title {
    font-size: 1.25rem;
  }

  .card-description {
    font-size: 0.9rem;
  }

  .card-list li {
    font-size: 0.8rem;
  }
  .container > .card:nth-child(1) {
    grid-row: 1;
    grid-column: 1;
  }
  .container > .card:nth-child(2) {
    grid-row: 2;
    grid-column: 1;
  }
  .container > .card:nth-child(3) {
    grid-row: 3;
    grid-column: 1;
  }
}
</style>
