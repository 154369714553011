import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/pages/HomePage.vue";
import AllOnFourPage from "@/pages/AllOnFourPage.vue";
import AllOnSixPage from "@/pages/AllOnSixPage.vue";
import BoneGraftPage from "@/pages/BoneGraftPage.vue";
import BotoxPage from "@/pages/BotoxPage.vue";
import BracesPage from "@/pages/BracesPage.vue";
import ClearAlignersPage from "@/pages/ClearAlignersPage.vue";
import CompositeFillingPage from "@/pages/CompositeFillingPage.vue";
import DentalVeneersPage from "@/pages/DentalVeneersPage.vue";
import DenturesPage from "@/pages/DenturesPage.vue";
import ExaminationPage from "@/pages/ExaminationPage.vue";
import FillersPage from "@/pages/FillersPage.vue";
import GumTreatmentsPage from "@/pages/GumTreatmentsPage.vue";
import HollywoodSmilePage from "@/pages/HollywoodSmilePage.vue";
import ImplantSupportedBridgePage from "@/pages/ImplantSupportedBridgePage.vue";
import MesotherapyPage from "@/pages/MesotherapyPage.vue";
import PorcelainMetalCrownsPage from "@/pages/PorcelainMetalCrownsPage.vue";
import RootCanalTreatmentPage from "@/pages/RootCanalTreatmentPage.vue";
import Scan3D from "@/pages/Scan3D.vue";
import SingleImplantPage from "@/pages/SingleImplantPage.vue";
import SinusLiftPage from "@/pages/SinusLiftPage.vue";
import TeethCleaningPage from "@/pages/TeethCleaningPage.vue";
import TeethWhiteningPage from "@/pages/TeethWhiteningPage.vue";
import ToothExtractionPage from "@/pages/ToothExtractionPage.vue";
import ZirconiaCrownsPage from "@/pages/ZirconiaCrownsPage.vue";
import BeforeAndAfterPage from "@/pages/BeforeAndAfterPage.vue";
import WhoWeArePage from "@/pages/WhoWeArePage.vue";
import ContactPage from "@/pages/ContactPage.vue";
import DentalTourismPage from "@/pages/DentalTourismPage.vue";
import GalleryPage from "@/pages/GalleryPage.vue";

//ALBANIAN
import HomePageAL from "@/pages/al/HomePageAL.vue";
import WhoWeArePageAL from "@/pages/al/WhoWeArePageAL.vue";
import AllOnFourPageAL from "@/pages/al/AllOnFourPageAL.vue";
import AllOnSixPageAL from "@/pages/al/AllOnSixPageAL.vue";
import BeforeAndAfterPageAL from "@/pages/al/BeforeAndAfterPageAL.vue";
import BoneGraftPageAL from "@/pages/al/BoneGraftPageAL.vue";
import BotoxPageAL from "@/pages/al/BotoxPageAL.vue";
import BracesPageAL from "@/pages/al/BracesPageAL.vue";
import ClearAlignersPageAL from "@/pages/al/ClearAlignersPageAL.vue";
import CompositeFillingPageAL from "@/pages/al/CompositeFillingPageAL.vue";
import ExaminationPageAL from "@/pages/al/ExaminationPageAL.vue";
import GumTreatmentsPageAL from "@/pages/al/GumTreatmentsPageAL.vue";
import MesotherapyPageAL from "@/pages/al/MesotherapyPageAL.vue";
import Scan3DAL from "@/pages/al/Scan3DAL.vue";
import TeethCleaningPageAL from "@/pages/al/TeethCleaningPageAL.vue";
import ToothExtractionPageAL from "@/pages/al/ToothExtractionPageAL.vue";
import RootCanalTreatmentPageAL from "@/pages/al/RootCanalTreatmentPageAL.vue";
import SingleImplantPageAL from "@/pages/al/SingleImplantPageAL.vue";
import ImplantSupportedBridgePageAL from "@/pages/al/ImplantSupportedBridgePageAL.vue";
import SinusLiftPageAL from "@/pages/al/SinusLiftPageAL.vue";
import ZirconiaCrownsPageAL from "@/pages/al/ZirconiaCrownsPageAL.vue";
import PorcelainMetalCrownsPageAL from "@/pages/al/PorcelainMetalCrownsPageAL.vue";
import DentalVeneersPageAL from "@/pages/al/DentalVeneersPageAL.vue";
import DenturesPageAL from "@/pages/al/DenturesPageAL.vue";
import HollywoodSmilePageAL from "@/pages/al/HollywoodSmilePageAL.vue";
import TeethWhiteningPageAL from "@/pages/al/TeethWhiteningPageAL.vue";
import FillersPageAL from "@/pages/al/FillersPageAL.vue";
import ContactPageAL from "@/pages/al/ContactPageAL.vue";
import GalleryPageAL from "@/pages/al/GalleryPageAL.vue";
import DentalTourismPageAL from "@/pages/al/DentalTourismPageAL.vue";

//ITALIAN
import HomePageIT from "@/pages/it/HomePageIT.vue";
import WhoWeArePageIT from "@/pages/it/WhoWeArePageIT.vue";
import ExaminationPageIT from "@/pages/it/ExaminationPageIT.vue";
import TeethCleaningPageIT from "@/pages/it/TeethCleaningPageIT.vue";
import Scan3DIT from "@/pages/it/Scan3DIT.vue";
import ToothExtractionPageIT from "@/pages/it/ToothExtractionPageIT.vue";
import GumTreatmentsPageIT from "@/pages/it/GumTreatmentsPageIT.vue";
import CompositeFillingPageIT from "@/pages/it/CompositeFillingPageIT.vue";
import RootCanalTreatmentPageIT from "@/pages/it/RootCanalTreatmentPageIT.vue";
import SingleImplantPageIT from "@/pages/it/SingleImplantPageIT.vue";
import ImplantSupportedBridgePageIT from "@/pages/it/ImplantSupportedBridgePageIT.vue";
import AllOnFourPageIT from "@/pages/it/AllOnFourPageIT.vue";
import AllOnSixPageIT from "@/pages/it/AllOnSixPageIT.vue";
import SinusLiftPageIT from "@/pages/it/SinusLiftPageIT.vue";
import BoneGraftPageIT from "@/pages/it/BoneGraftPageIT.vue";
import ZirconiaCrownsPageIT from "@/pages/it/ZirconiaCrownsPageIT.vue";
import PorcelainMetalCrownsPageIT from "@/pages/it/PorcelainMetalCrownsPageIT.vue";
import DentalVeneersPageIT from "@/pages/it/DentalVeneersPageIT.vue";
import DenturesPageIT from "@/pages/it/DenturesPageIT.vue";
import HollywoodSmilePageIT from "@/pages/it/HollywoodSmilePageIT.vue";
import TeethWhiteningPageIT from "@/pages/it/TeethWhiteningPageIT.vue";
import FillersPageIT from "@/pages/it/FillersPageIT.vue";
import BotoxPageIT from "@/pages/it/BotoxPageIT.vue";
import MesotherapyPageIT from "@/pages/it/MesotherapyPageIT.vue";
import ClearAlignersPageIT from "@/pages/it/ClearAlignersPageIT.vue";
import BracesPageIT from "@/pages/it/BracesPageIT.vue";
import DentalTourismPageIT from "@/pages/it/DentalTourismPageIT.vue";
import BeforeAndAfterPageIT from "@/pages/it/BeforeAndAfterPageIT.vue";
import ContactPageIT from "@/pages/it/ContactPageIT.vue";
import GalleryPageIT from "@/pages/it/GalleryPageIT.vue";

//SPANISH
import HomePageES from "@/pages/es/HomePageES.vue";
import WhoWeArePageES from "@/pages/es/WhoWeArePageES.vue";
import AllOnFourPageES from "@/pages/es/AllOnFourPageES.vue";
import AllOnSixPageES from "@/pages/es/AllOnSixPageES.vue";
import BeforeAndAfterPageES from "@/pages/es/BeforeAndAfterPageES.vue";
import BoneGraftPageES from "@/pages/es/BoneGraftPageES.vue";
import BotoxPageES from "@/pages/es/BotoxPageES.vue";
import BracesPageES from "@/pages/es/BracesPageES.vue";
import ClearAlignersPageES from "@/pages/es/ClearAlignersPageES.vue";
import CompositeFillingPageES from "@/pages/es/CompositeFillingPageES.vue";
import ExaminationPageES from "@/pages/es/ExaminationPageES.vue";
import GumTreatmentsPageES from "@/pages/es/GumTreatmentsPageES.vue";
import MesotherapyPageES from "@/pages/es/MesotherapyPageES.vue";
import Scan3DES from "@/pages/es/Scan3DES.vue";
import TeethCleaningPageES from "@/pages/es/TeethCleaningPageES.vue";
import ToothExtractionPageES from "@/pages/es/ToothExtractionPageES.vue";
import RootCanalTreatmentPageES from "@/pages/es/RootCanalTreatmentPageES.vue";
import SingleImplantPageES from "@/pages/es/SingleImplantPageES.vue";
import ImplantSupportedBridgePageES from "@/pages/es/ImplantSupportedBridgePageES.vue";
import SinusLiftPageES from "@/pages/es/SinusLiftPageES.vue";
import ZirconiaCrownsPageES from "@/pages/es/ZirconiaCrownsPageES.vue";
import PorcelainMetalCrownsPageES from "@/pages/es/PorcelainMetalCrownsPageES.vue";
import DentalVeneersPageES from "@/pages/es/DentalVeneersPageES.vue";
import DenturesPageES from "@/pages/es/DenturesPageES.vue";
import HollywoodSmilePageES from "@/pages/es/HollywoodSmilePageES.vue";
import TeethWhiteningPageES from "@/pages/es/TeethWhiteningPageES.vue";
import FillersPageES from "@/pages/es/FillersPageES.vue";
import ContactPageES from "@/pages/es/ContactPageES.vue";
import GalleryPageES from "@/pages/es/GalleryPageES.vue";
import DentalTourismPageES from "@/pages/es/DentalTourismPageES.vue";

const routes = [
  {
    path: "/",
    component: HomePage,
    meta: {
      title: "Home - HMC",
    },
  },
  {
    path: "/all-on-four",
    component: AllOnFourPage,
    meta: {
      title: "All on Four - HMC",
    },
  },
  {
    path: "/all-on-six",
    component: AllOnSixPage,
    meta: {
      title: "All on Six - HMC",
    },
  },
  {
    path: "/bone-graft",
    component: BoneGraftPage,
    meta: {
      title: "Bone Graft - HMC",
    },
  },
  {
    path: "/botox",
    component: BotoxPage,
    meta: {
      title: "Botox - HMC",
    },
  },
  {
    path: "/braces",
    component: BracesPage,
    meta: {
      title: "Braces - HMC",
    },
  },
  {
    path: "/clear-aligners",
    component: ClearAlignersPage,
    meta: {
      title: "Clear Aligners - HMC",
    },
  },
  {
    path: "/composite-filling",
    component: CompositeFillingPage,
    meta: {
      title: "Composite Filling - HMC",
    },
  },
  {
    path: "/dental-veneers",
    component: DentalVeneersPage,
    meta: {
      title: "Dental Veneers - HMC",
    },
  },
  {
    path: "/dentures",
    component: DenturesPage,
    meta: {
      title: "Dentures - HMC",
    },
  },
  {
    path: "/examination",
    component: ExaminationPage,
    meta: {
      title: "Examination - HMC",
    },
  },
  {
    path: "/fillers",
    component: FillersPage,
    meta: {
      title: "Fillers - HMC",
    },
  },
  {
    path: "/gum-treatments",
    component: GumTreatmentsPage,
    meta: {
      title: "Gum Treatments - HMC",
    },
  },
  {
    path: "/hollywood-smile",
    component: HollywoodSmilePage,
    meta: {
      title: "Hollywood Smile - HMC",
    },
  },
  {
    path: "/implant-supported-bridge",
    component: ImplantSupportedBridgePage,
    meta: {
      title: "Implant Supported Bridge - HMC",
    },
  },
  {
    path: "/mesotherapy",
    component: MesotherapyPage,
    meta: {
      title: "Mesotherapy - HMC",
    },
  },
  {
    path: "/porcelain-metal-crowns",
    component: PorcelainMetalCrownsPage,
    meta: {
      title: "Porcelain Metal Crowns - HMC",
    },
  },
  {
    path: "/root-canal-treatment",
    component: RootCanalTreatmentPage,
    meta: {
      title: "Root Canal Treatment - HMC",
    },
  },
  {
    path: "/3d-scan",
    component: Scan3D,
    meta: {
      title: "Scan 3D - HMC",
    },
  },
  {
    path: "/single-implant",
    component: SingleImplantPage,
    meta: {
      title: "Single Implant - HMC",
    },
  },
  {
    path: "/sinus-lift",
    component: SinusLiftPage,
    meta: {
      title: "Sinus Lift - HMC",
    },
  },
  {
    path: "/teeth-cleaning",
    component: TeethCleaningPage,
    meta: {
      title: "Teeth Cleaning - HMC",
    },
  },
  {
    path: "/teeth-whitening",
    component: TeethWhiteningPage,
    meta: {
      title: "Teeth Whitening - HMC",
    },
  },
  {
    path: "/tooth-extraction",
    component: ToothExtractionPage,
    meta: {
      title: "Tooth Extraction - HMC",
    },
  },
  {
    path: "/zirconia-crowns",
    component: ZirconiaCrownsPage,
    meta: {
      title: "Zirconia Crowns - HMC",
    },
  },
  {
    path: "/before-after",
    component: BeforeAndAfterPage,
    meta: {
      title: "Before and After - HMC",
    },
  },
  {
    path: "/who-we-are",
    component: WhoWeArePage,
    meta: {
      title: "Who We Are - HMC",
    },
  },
  {
    path: "/contact",
    component: ContactPage,
    meta: {
      title: "Who We Are - HMC",
    },
  },
  {
    path: "/dental-tourism",
    component: DentalTourismPage,
    meta: {
      title: "Dental Tourism - HMC",
    },
  },
  {
    path: "/gallery",
    component: GalleryPage,
    meta: {
      title: "Gallery - HMC",
    },
  },

  //ALBANIAN LANG
  {
    path: "/al/all-on-four",
    component: AllOnFourPageAL,
    meta: {
      title: "All on Four - HMC",
    },
  },

  {
    path: "/al/all-on-six",
    component: AllOnSixPageAL,
    meta: {
      title: "All on Six - HMC",
    },
  },

  {
    path: "/al/before-after",
    component: BeforeAndAfterPageAL,
    meta: {
      title: "Before and After - HMC",
    },
  },

  {
    path: "/al/bone-graft",
    component: BoneGraftPageAL,
    meta: {
      title: "Bone Graft - HMC",
    },
  },

  {
    path: "/al/botox",
    component: BotoxPageAL,
    meta: {
      title: "Botox - HMC",
    },
  },
  {
    path: "/al/braces",
    component: BracesPageAL,
    meta: {
      title: "Braces - HMC",
    },
  },

  {
    path: "/al/clear-aligners",
    component: ClearAlignersPageAL,
    meta: {
      title: "Clear Aligners - HMC",
    },
  },

  {
    path: "/al/composite-filling",
    component: CompositeFillingPageAL,
    meta: {
      title: "Composite Filling - HMC",
    },
  },

  {
    path: "/al/examination",
    component: ExaminationPageAL,
    meta: {
      title: "Examination - HMC",
    },
  },
  {
    path: "/al/gum-treatments",
    component: GumTreatmentsPageAL,
    meta: {
      title: "Gum Treatments - HMC",
    },
  },
  {
    path: "/al/tooth-extraction",
    component: ToothExtractionPageAL,
    meta: {
      title: "Tooth Extraction - HMC",
    },
  },
  {
    path: "/al/mesotherapy",
    component: MesotherapyPageAL,
    meta: {
      title: "Mesotherapy - HMC",
    },
  },
  {
    path: "/al/3d-scan",
    component: Scan3DAL,
    meta: {
      title: "Scan 3D - HMC",
    },
  },
  {
    path: "/al/teeth-cleaning",
    component: TeethCleaningPageAL,
    meta: {
      title: "Teeth Cleaning - HMC",
    },
  },
  {
    path: "/al/root-canal-treatment",
    component: RootCanalTreatmentPageAL,
    meta: {
      title: "Root Canal Treatment - HMC",
    },
  },
  {
    path: "/al/single-implant",
    component: SingleImplantPageAL,
    meta: {
      title: "Single Implant - HMC",
    },
  },
  {
    path: "/al/implant-supported-bridge",
    component: ImplantSupportedBridgePageAL,
    meta: {
      title: "Implant Supported Bridge - HMC",
    },
  },
  {
    path: "/al/sinus-lift",
    component: SinusLiftPageAL,
    meta: {
      title: "Sinus Lift - HMC",
    },
  },
  {
    path: "/al/zirconia-crowns",
    component: ZirconiaCrownsPageAL,
    meta: {
      title: "Zirconia Crowns - HMC",
    },
  },
  {
    path: "/al/porcelain-metal-crowns",
    component: PorcelainMetalCrownsPageAL,
    meta: {
      title: "Porcelain Metal Crowns - HMC",
    },
  },
  {
    path: "/al/dental-veneers",
    component: DentalVeneersPageAL,
    meta: {
      title: "Dental Veneers - HMC",
    },
  },
  {
    path: "/al/dentures",
    component: DenturesPageAL,
    meta: {
      title: "Dentures - HMC",
    },
  },
  {
    path: "/al/hollywood-smile",
    component: HollywoodSmilePageAL,
    meta: {
      title: "Hollywood Smile - HMC",
    },
  },
  {
    path: "/al/teeth-whitening",
    component: TeethWhiteningPageAL,
    meta: {
      title: "Teeth Whitening - HMC",
    },
  },
  {
    path: "/al/fillers",
    component: FillersPageAL,
    meta: {
      title: "Fillers - HMC",
    },
  },
  {
    path: "/al/contact",
    component: ContactPageAL,
    meta: {
      title: "Who We Are - HMC",
    },
  },
  {
    path: "/al/gallery",
    component: GalleryPageAL,
    meta: {
      title: "Gallery - HMC",
    },
  },
  {
    path: "/al/dental-tourism",
    component: DentalTourismPageAL,
    meta: {
      title: "Dental Tourism - HMC",
    },
  },
  {
    path: "/al/who-we-are",
    component: WhoWeArePageAL,
    meta: {
      title: "Who We Are - HMC",
    },
  },
  {
    path: "/al",
    component: HomePageAL,
    meta: {
      title: "Home - HMC",
    },
  },

  //ITALIAN
  {
    path: "/it",
    component: HomePageIT,
    meta: {
      title: "Home - HMC",
    },
  },
  {
    path: "/it/who-we-are",
    component: WhoWeArePageIT,
    meta: {
      title: "Who We Are - HMC",
    },
  },
  {
    path: "/it/examination",
    component: ExaminationPageIT,
    meta: {
      title: "Examination - HMC",
    },
  },
  {
    path: "/it/teeth-cleaning",
    component: TeethCleaningPageIT,
    meta: {
      title: "Teeth Cleaning - HMC",
    },
  },
  {
    path: "/it/3d-scan",
    component: Scan3DIT,
    meta: {
      title: "Scan 3D - HMC",
    },
  },
  {
    path: "/it/tooth-extraction",
    component: ToothExtractionPageIT,
    meta: {
      title: "Tooth Extraction - HMC",
    },
  },
  {
    path: "/it/gum-treatments",
    component: GumTreatmentsPageIT,
    meta: {
      title: "Gum Treatments - HMC",
    },
  },
  {
    path: "/it/composite-filling",
    component: CompositeFillingPageIT,
    meta: {
      title: "Composite Filling - HMC",
    },
  },
  {
    path: "/it/root-canal-treatment",
    component: RootCanalTreatmentPageIT,
    meta: {
      title: "Root Canal Treatment - HMC",
    },
  },
  {
    path: "/it/single-implant",
    component: SingleImplantPageIT,
    meta: {
      title: "Single Implant - HMC",
    },
  },
  {
    path: "/it/implant-supported-bridge",
    component: ImplantSupportedBridgePageIT,
    meta: {
      title: "Implant Supported Bridge - HMC",
    },
  },
  {
    path: "/it/all-on-four",
    component: AllOnFourPageIT,
    meta: {
      title: "All on Four - HMC",
    },
  },
  {
    path: "/it/all-on-six",
    component: AllOnSixPageIT,
    meta: {
      title: "All on Six - HMC",
    },
  },
  {
    path: "/it/sinus-lift",
    component: SinusLiftPageIT,
    meta: {
      title: "Sinus Lift - HMC",
    },
  },
  {
    path: "/it/bone-graft",
    component: BoneGraftPageIT,
    meta: {
      title: "Bone Graft - HMC",
    },
  },
  {
    path: "/it/zirconia-crowns",
    component: ZirconiaCrownsPageIT,
    meta: {
      title: "Zirconia Crowns - HMC",
    },
  },
  {
    path: "/it/porcelain-metal-crowns",
    component: PorcelainMetalCrownsPageIT,
    meta: {
      title: "Porcelain Metal Crowns - HMC",
    },
  },
  {
    path: "/it/dental-veneers",
    component: DentalVeneersPageIT,
    meta: {
      title: "Dental Veneers - HMC",
    },
  },
  {
    path: "/it/dentures",
    component: DenturesPageIT,
    meta: {
      title: "Dentures - HMC",
    },
  },
  {
    path: "/it/hollywood-smile",
    component: HollywoodSmilePageIT,
    meta: {
      title: "Hollywood Smile - HMC",
    },
  },
  {
    path: "/it/teeth-whitening",
    component: TeethWhiteningPageIT,
    meta: {
      title: "Teeth Whitening - HMC",
    },
  },
  {
    path: "/it/fillers",
    component: FillersPageIT,
    meta: {
      title: "Fillers - HMC",
    },
  },
  {
    path: "/it/botox",
    component: BotoxPageIT,
    meta: {
      title: "Botox - HMC",
    },
  },
  {
    path: "/it/mesotherapy",
    component: MesotherapyPageIT,
    meta: {
      title: "Mesotherapy - HMC",
    },
  },
  {
    path: "/it/clear-aligners",
    component: ClearAlignersPageIT,
    meta: {
      title: "Clear Aligners - HMC",
    },
  },
  {
    path: "/it/braces",
    component: BracesPageIT,
    meta: {
      title: "Braces - HMC",
    },
  },
  {
    path: "/it/dental-tourism",
    component: DentalTourismPageIT,
    meta: {
      title: "Dental Tourism - HMC",
    },
  },
  {
    path: "/it/before-after",
    component: BeforeAndAfterPageIT,
    meta: {
      title: "Before and After - HMC",
    },
  },
  {
    path: "/it/contact",
    component: ContactPageIT,
    meta: {
      title: "Who We Are - HMC",
    },
  },
  {
    path: "/it/gallery",
    component: GalleryPageIT,
    meta: {
      title: "Gallery - HMC",
    },
  },

  //SPANISH
  {
    path: "/es",
    component: HomePageES,
    meta: {
      title: "Inicio - HMC",
    },
  },
  {
    path: "/es/who-we-are",
    component: WhoWeArePageES,
    meta: {
      title: "Quiénes Somos - HMC",
    },
  },
  {
    path: "/es/all-on-four",
    component: AllOnFourPageES,
    meta: {
      title: "All on Four - HMC",
    },
  },
  {
    path: "/es/all-on-six",
    component: AllOnSixPageES,
    meta: {
      title: "All on Six - HMC",
    },
  },
  {
    path: "/es/before-after",
    component: BeforeAndAfterPageES,
    meta: {
      title: "Antes y Después - HMC",
    },
  },
  {
    path: "/es/bone-graft",
    component: BoneGraftPageES,
    meta: {
      title: "Injerto Óseo - HMC",
    },
  },
  {
    path: "/es/botox",
    component: BotoxPageES,
    meta: {
      title: "Botox - HMC",
    },
  },
  {
    path: "/es/braces",
    component: BracesPageES,
    meta: {
      title: "Brackets - HMC",
    },
  },
  {
    path: "/es/clear-aligners",
    component: ClearAlignersPageES,
    meta: {
      title: "Alineadores Transparentes - HMC",
    },
  },
  {
    path: "/es/composite-filling",
    component: CompositeFillingPageES,
    meta: {
      title: "Empaste Compuesto - HMC",
    },
  },
  {
    path: "/es/examination",
    component: ExaminationPageES,
    meta: {
      title: "Examen - HMC",
    },
  },
  {
    path: "/es/gum-treatments",
    component: GumTreatmentsPageES,
    meta: {
      title: "Tratamientos de Encías - HMC",
    },
  },
  {
    path: "/es/tooth-extraction",
    component: ToothExtractionPageES,
    meta: {
      title: "Extracción Dental - HMC",
    },
  },
  {
    path: "/es/mesotherapy",
    component: MesotherapyPageES,
    meta: {
      title: "Mesoterapia - HMC",
    },
  },
  {
    path: "/es/3d-scan",
    component: Scan3DES,
    meta: {
      title: "Escaneo 3D - HMC",
    },
  },
  {
    path: "/es/teeth-cleaning",
    component: TeethCleaningPageES,
    meta: {
      title: "Limpieza Dental - HMC",
    },
  },
  {
    path: "/es/root-canal-treatment",
    component: RootCanalTreatmentPageES,
    meta: {
      title: "Tratamiento de Conductos - HMC",
    },
  },
  {
    path: "/es/single-implant",
    component: SingleImplantPageES,
    meta: {
      title: "Implante Único - HMC",
    },
  },
  {
    path: "/es/implant-supported-bridge",
    component: ImplantSupportedBridgePageES,
    meta: {
      title: "Puente Soportado por Implante - HMC",
    },
  },
  {
    path: "/es/sinus-lift",
    component: SinusLiftPageES,
    meta: {
      title: "Elevación de Seno - HMC",
    },
  },
  {
    path: "/es/zirconia-crowns",
    component: ZirconiaCrownsPageES,
    meta: {
      title: "Coronas de Zirconio - HMC",
    },
  },
  {
    path: "/es/porcelain-metal-crowns",
    component: PorcelainMetalCrownsPageES,
    meta: {
      title: "Coronas Metálico-Porcelanas - HMC",
    },
  },
  {
    path: "/es/dental-veneers",
    component: DentalVeneersPageES,
    meta: {
      title: "Carillas Dentales - HMC",
    },
  },
  {
    path: "/es/dentures",
    component: DenturesPageES,
    meta: {
      title: "Dentaduras - HMC",
    },
  },
  {
    path: "/es/hollywood-smile",
    component: HollywoodSmilePageES,
    meta: {
      title: "Sonrisa de Hollywood - HMC",
    },
  },
  {
    path: "/es/teeth-whitening",
    component: TeethWhiteningPageES,
    meta: {
      title: "Blanqueamiento Dental - HMC",
    },
  },
  {
    path: "/es/fillers",
    component: FillersPageES,
    meta: {
      title: "Rellenos - HMC",
    },
  },
  {
    path: "/es/contact",
    component: ContactPageES,
    meta: {
      title: "Contacto - HMC",
    },
  },
  {
    path: "/es/gallery",
    component: GalleryPageES,
    meta: {
      title: "Galería - HMC",
    },
  },
  {
    path: "/es/dental-tourism",
    component: DentalTourismPageES,
    meta: {
      title: "Turismo Dental - HMC",
    },
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // Always scroll to the top
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "HMC";
  next();
});

export default router;
