<template>
  <section class="info-section">
    <div class="info-header">
      <div class="info-icon">
        <i class="fa-solid fa-circle-info fa-custom-clinic"></i>
      </div>
      <div class="info-text">
        <h2>Para Takimit</h2>
        <p>
          Ndihuni të lirë të sillni një mik ose anëtar të familjes në vizitat
          potencialisht stresuese.
        </p>
      </div>
    </div>
  </section>
</template>

<style scoped>
.info-section {
  background-color: #f7f4fc;
  border: 1px solid #e5e5e5;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.info-header {
  display: flex;
  align-items: center;
}

.info-icon {
  font-size: 34px;
  color: #6c63ff;
  margin-right: 10px;
}

.info-text h2 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.info-text p {
  color: #666;
  margin-top: 5px;
}

.fa-custom-clinic {
  font-size: 34px;
  margin-right: 5px;
}
</style>
