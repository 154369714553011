<template>
  <div class="language-dropdown">
    <button class="dropdown-toggle">
      <i :class="currentFlagClass"></i>
      <span>{{ currentLanguage.toUpperCase() }}</span>
    </button>
    <div class="dropdown-menu">
      <a
        href="#"
        class="dropdown-item"
        v-for="lang in languages"
        :key="lang.code"
        @click.prevent="switchLanguage(lang.code)"
      >
        <i :class="lang.flagClass"></i> {{ lang.name }}
      </a>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "LanguageSwitcherComponent",
  setup() {
    const route = useRoute();
    const router = useRouter();

    const currentLanguage = ref("en"); // Default language
    const languages = [
      { code: "en", name: "English", flagClass: "flag-icon flag-icon-gb" },
      { code: "al", name: "Albanian", flagClass: "flag-icon flag-icon-al" },
      { code: "it", name: "Italian", flagClass: "flag-icon flag-icon-it" },
      { code: "es", name: "Spanish", flagClass: "flag-icon flag-icon-es" },
    ];

    // Get the current flag class for the selected language
    const currentFlagClass = computed(() => {
      const lang = languages.find((l) => l.code === currentLanguage.value);
      return lang ? lang.flagClass : "flag-icon flag-icon-gb"; // Default to English flag
    });

    const switchLanguage = (lang) => {
      // Extract the current path without the language prefix
      const pathWithoutLang = route.path.replace(/^\/(en|al|it|es)/, "");

      // Construct the new path with the selected language
      const newPath =
        lang === "en" ? pathWithoutLang : `/${lang}${pathWithoutLang}`;

      // Prevent unnecessary navigation
      if (route.path === newPath) return;

      // Update the current language
      currentLanguage.value = lang;

      // Navigate to the new path
      router.push(newPath);
    };

    // Automatically set the current language based on the route
    watch(
      () => route.path,
      () => {
        const langMatch = route.path.match(/^\/(en|al|it)/);
        currentLanguage.value = langMatch ? langMatch[1] : "en";
      },
      { immediate: true }
    );

    return {
      currentLanguage,
      languages,
      currentFlagClass,
      switchLanguage,
    };
  },
};
</script>

<style scoped>
.language-dropdown {
  position: relative;
  align-content: center;
  z-index: 444;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: color 0.2s;
}

.dropdown-toggle:hover {
  color: #c49754;
}

.dropdown-toggle span {
  margin-left: 5px;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: white;
  border-radius: 0.375rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding: 0.5rem 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
}

.language-dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: #333;
  text-decoration: none;
  transition: background-color 0.2s, color 0.2s;
}

.dropdown-item:hover {
  background-color: #f3f4f6;
  color: #c49754;
}

.dropdown-item i {
  margin-right: 0.5rem;
  font-size: 1rem;
}
</style>
