<template>
  <div id="faq-segment">
    <info-card v-if="infoCard == 'yes'"></info-card>

    <h3 v-if="faqHeader">{{ faqHeader }}</h3>

    <section class="accordion" v-for="f in faqS" :key="f.id">
      <div>
        <div class="accordion-item">
          <button class="accordion-header">
            {{ f.question }}
          </button>
          <div class="accordion-content">
            <p v-html="f.answer"></p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import InfoCard from "@/components/it/InfoCardIT.vue";

export default {
  components: { InfoCard },
  props: {
    infoCard: {
      type: String,
      default: null,
    },
    faqHeader: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      faqS: [
        {
          id: 0,
          question:
            "Quali informazioni devo fornire per prenotare un appuntamento?",
          answer:
            "Abbiamo bisogno solo del tuo nome completo e del tuo numero di telefono. Il nostro team di assistenza ai pazienti ti chiamerà per fissare un appuntamento in base alla tua disponibilità. In alternativa, puoi fissare l’appuntamento direttamente attraverso il nostro sito web.",
        },
        {
          id: 1,
          question:
            "Mi sento nervoso/a quando sono solo/a. Posso portare qualcuno con me?",
          answer:
            "Sì, perché no? Vogliamo che tu ti senta rilassato e a casa durante il trattamento presso Health Medical Center. Sebbene siamo certi che ti sentirai immediatamente rilassato non appena entrerai nel nostro studio, puoi portare con te i tuoi cari o amici per un supporto morale.",
        },
        {
          id: 2,
          question: "Quanto tempo prima devo arrivare per il mio appuntamento?",
          answer:
            "Sta a te decidere quanto presto vuoi arrivare per l’appuntamento. Se desideri risparmiare tempo, puoi arrivare puntuale. Tuttavia, ti consigliamo di arrivare leggermente in anticipo per poter gustare una tazza di tè o caffè con noi, rilassarti su una delle nostre poltrone massaggianti, o semplicemente goderti l’ambiente sereno e la musica rilassante nella nostra area d’attesa.",
        },
        {
          id: 3,
          question:
            "Cosa succede se devo cambiare la data o l’orario del mio appuntamento?",
          answer:
            "AAnche se non consigliamo di cambiare l’orario o la data dell’appuntamento all’ultimo minuto, comprendiamo che possano sorgere emergenze in qualsiasi momento. Ti chiediamo tuttavia di informarci il prima possibile se non puoi arrivare all’appuntamento. Ti preghiamo di chiamarci al +355 69 20 96 720 e saremo felici di riprogrammare il tuo appuntamento..",
        },
        {
          id: 4,
          question:
            "Quanto tempo ci vuole per completare una procedura di impianto dentale?",
          answer:
            "Il processo di solito richiede alcuni mesi, con la prima fase che consiste nell’inserimento dell’impianto e la fase di guarigione successiva. La restaurazione finale viene eseguita dopo che l’impianto si è integrato con l’osso.",
        },
        {
          id: 5,
          question:
            "Posso combinare più trattamenti dentali in una sola visita?",
          answer:
            "Sì, a seconda dei trattamenti e del tuo caso specifico, spesso possiamo combinare le procedure per ridurre al minimo i tuoi spostamenti e ottimizzare il tuo tempo",
        },
        {
          id: 6,
          question: "Offrite sedazione per i pazienti ansiosi?",
          answer:
            "Sì, offriamo opzioni di sedazione per i pazienti che si sentono ansiosi durante le procedure dentali, per garantire un’esperienza confortevole e senza stress.",
        },
        {
          id: 7,
          question:
            "Ho bisogno di un visto per viaggiare in Albania per un trattamento dentale?",
          answer:
            "I requisiti per il visto dipendono dal tuo paese di origine. Molti visitatori dall’Europa non necessitano di un visto per soggiorni brevi. Ti invitiamo a verificare i requisiti più recenti o a contattarci per assistenza.",
        },
        {
          id: 8,
          question:
            "Quanto dista la clinica dall’aeroporto e offrite un servizio di trasporto?",
          answer:
            "La nostra clinica è comodamente situata a 17 km dall’aeroporto e offriamo trasporto gratuito da e per l’aeroporto per i nostri pazienti internazionali.",
        },
        {
          id: 9,
          question: "Posso avere una consulenza prima di arrivare?",
          answer:
            "Sì, offriamo consultazioni virtuali gratuite per i pazienti internazionali per discutere le tue esigenze dentali e fornire un piano di trattamento preliminare prima di viaggiare. Una volta arrivato in clinica, offriamo una consultazione in persona gratuita insieme a scansioni 3D o radiografie necessarie, garantendo una valutazione completa senza costi aggiuntivi.",
        },
        {
          id: 10,
          question: "Che tipo di assistenza post-trattamento fornite?",
          answer:
            "Forniamo istruzioni dettagliate post-trattamento e il nostro team è sempre disponibile per consulenze di follow-up, sia in persona che online, per garantire che la tua guarigione proceda senza problemi.",
        },
        {
          id: 11,
          question: "Dovrò tornare per visite di controllo?",
          answer:
            "Per la maggior parte dei trattamenti, il follow-up può essere effettuato virtualmente. Tuttavia, per alcune procedure come gli impianti o gli aligner trasparenti, possiamo consigliare un follow-up in persona per garantire risultati ottimali.",
        },
      ],
    };
  },
  computed: {
    faqs() {
      return this.faqItems && this.faqItems.length > 0
        ? this.faqItems
        : this.defFaqs;
    },
  },
  mounted() {
    const accordionHeaders = this.$el.querySelectorAll(".accordion-header");

    accordionHeaders.forEach((header) => {
      header.addEventListener("click", function () {
        const isActive = this.classList.contains("active");

        accordionHeaders.forEach((hdr) => {
          if (hdr !== this) {
            hdr.classList.remove("active");
            hdr.parentElement.classList.remove("hoverBl");
            hdr.nextElementSibling.style.maxHeight = null;
          }
        });

        const activeItem = this.nextElementSibling;

        if (isActive) {
          this.classList.remove("active");
          this.parentElement.classList.remove("hoverBl");
          activeItem.style.maxHeight = null;
        } else {
          this.classList.add("active");
          this.parentElement.classList.add("hoverBl");
          activeItem.style.maxHeight = activeItem.scrollHeight + "px";
        }
      });
    });
  },
};
</script>

<style scoped>
.accordion h3 {
  color: #6c63ff;
  font-size: 18px;
  margin-bottom: 10px;
}

.accordion-item {
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  font-family: Gilroy !important;
}

.accordion-item:first-of-type {
  border-top: 1px solid #e5e5e5;
}
.accordion-header {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-family: Gilroy;
  font-weight: 400;
  border: none;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-header:after {
  content: "+";
  font-size: 18px;
  color: #c49754;
}

.accordion-header.active:after {
  content: "-";
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background-color: #c49754;
}
.accordion-content > p {
  padding: 20px;
  line-height: 2;
}

.hoverBl {
  border: 1.4px solid #c49754 !important;
}

#faq-segment > h3 {
  color: #c49754;
  margin-bottom: 10px;
  font-size: 24px;
}
</style>
