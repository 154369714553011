<template>
  <div class="appointment-container">
    <h2>{{ formTitle }}</h2>

    <p>
      Ju lutemi telefononi
      <a href="tel:+355692096720" class="phone-link"
        ><i class="fas fa-phone"></i> +355 69 20 96 720</a
      >
      nëse është urgjente.<br />
      Menaxheri juaj personal do të sigurojë që të merrni kujdesin më të mirë të
      mundshëm.
    </p>

    <form class="appointment-form">
      <div class="form-group">
        <label for="name">Emri Juaj - Mbiemri *</label>
        <input type="text" id="name" name="name" required />
      </div>

      <div class="form-group">
        <label for="email">Adresa Juaj e Emailit *</label>
        <input type="email" id="email" name="email" required />
      </div>

      <div class="form-group">
        <label for="phone">Numri Juaj i Telefonit *</label>
        <input type="tel" id="phone" name="phone" required />
      </div>

      <div class="form-group">
        <label for="appointment-date">Data e Takimit</label>
        <input type="date" id="appointment-date" name="appointment-date" />
      </div>

      <div class="form-group">
        <label for="message">Mesazh Shtesë</label>
        <textarea id="message" name="message" rows="4"></textarea>
      </div>

      <div class="form-group checkbox-group">
        <input type="checkbox" id="marketing" name="marketing" />
        <label for="marketing">Pranoj të marr komunikime për marketing</label>
      </div>

      <div class="form-group checkbox-group">
        <input type="checkbox" id="terms" name="terms" required />
        <label for="terms"
          >Pranoj kushtet e shërbimit dhe politikën e përdorimit të të
          dhënave</label
        >
      </div>

      <button type="submit" class="appointment-btn">BËNI NJË TAKIM</button>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    formTitle: {
      type: String,
      default: "Rezervo takim",
    },
  },
};
</script>

<style>
.appointment-container {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.appointment-container h2 {
  font-size: 24px;
  color: #c49754;
  margin-bottom: 20px;
  text-align: center;
}

.appointment-container p {
  font-size: 14px;
  color: #666;
  text-align: center;
  margin-bottom: 20px;
}

.phone-link {
  color: #c49754;
  font-weight: bold;
  text-decoration: none;
  background-color: #f2f2f2;
  padding: 5px 10px;
  border-radius: 5px;
}

.appointment-form .form-group {
  margin-bottom: 15px;
}

.appointment-form label {
  display: block;
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

.appointment-form input,
.appointment-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  background-color: #fafafa;
}

.appointment-form input[type="date"] {
  font-family: Arial, sans-serif; /* For consistent styling across browsers */
}

.checkbox-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 10px;
  width: fit-content;
}

.appointment-btn {
  width: 100%;
  padding: 12px;
  background-color: #c49754;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
}

.appointment-btn:hover {
  background-color: #2d2c56;
}
</style>
