<template>
  <nav-bar-child></nav-bar-child>
  <nav-bar></nav-bar>
  <generic-image-holder-component></generic-image-holder-component>

  <div id="grid-container">
    <section class="left-side">
      <div id="gallery-container">
        <image-comparison-component
          beforeImg="before1"
          afterImg="after1"
        ></image-comparison-component>
        <image-comparison-component
          beforeImg="before2"
          afterImg="after2"
        ></image-comparison-component>
        <image-comparison-component
          beforeImg="before3"
          afterImg="after3"
        ></image-comparison-component>
        <image-comparison-component
          beforeImg="before4"
          afterImg="after4"
        ></image-comparison-component>
        <image-comparison-component
          beforeImg="before5"
          afterImg="after5"
        ></image-comparison-component>
        <image-comparison-component
          beforeImg="before6"
          afterImg="after6"
        ></image-comparison-component>
        <image-comparison-component
          beforeImg="before7"
          afterImg="after7"
        ></image-comparison-component>
        <image-comparison-component
          beforeImg="before8"
          afterImg="after8"
        ></image-comparison-component>
        <image-comparison-component
          beforeImg="before9"
          afterImg="after9"
        ></image-comparison-component>
      </div>
    </section>
    <section class="right-side">
      <form-appointment formTitle="Transforma Tu Sonrisa"></form-appointment>
    </section>
  </div>
  <footer-component></footer-component>
</template>

<script>
import ImageComparisonComponent from "@/components/es/ImageComparisonComponentES.vue";
import NavBar from "@/components/es/NavBarES.vue";
import NavBarChild from "@/components/es/NavBarChildES.vue";
import FormAppointment from "@/components/es/FormAppointmentES.vue";
import GenericImageHolderComponent from "@/components/es/GenericImageHolderComponentES.vue";
import FooterComponent from "@/components/es/FooterComponentES.vue";

export default {
  components: {
    NavBar,
    NavBarChild,
    ImageComparisonComponent,
    FormAppointment,
    GenericImageHolderComponent,
    FooterComponent,
  },
};
</script>

<style scoped>
#grid-container {
  display: grid;
  grid-template-columns: 70% 30%;
  column-gap: 50px;
  max-width: 80%;
  width: 100%;
  margin: auto;
  margin-top: 50px;
}

#gallery-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 20px;
}

@media all and (max-width: 1200px) {
  #grid-container {
    grid-template-columns: 1fr;
    max-width: 92%;
  }

  #gallery-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
