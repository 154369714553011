<template>
  <div class="container">
    <div class="column">
      <h2>La Nostra Missione</h2>
      <p>
        La nostra missione è offrire cure dentali di livello mondiale con
        un’attenzione particolare al comfort del paziente, alla qualità e alla
        convenienza. Siamo dedicati a fornire trattamenti personalizzati che
        rispondano alle esigenze uniche di ogni paziente, garantendo risultati
        eccezionali. Che si tratti di cure dentali o di servizi estetici
        selezionati, ci impegniamo a rendere il percorso di ogni paziente
        fluido, soddisfacente e trasformativo.
      </p>
    </div>
    <div class="column">
      <h2>La Nostra Visione</h2>
      <p>
        Presso Health Medical Center, aspiriamo a diventare una destinazione di
        eccellenza per cure dentali e estetiche complete. Il nostro obiettivo è
        fornire trattamenti straordinari che non solo migliorano la salute, ma
        accrescono anche la fiducia e il benessere. Combinando innovazione,
        competenza e un approccio personalizzato, ci impegniamo a creare sorrisi
        belli e duraturi, regalando esperienze indimenticabili ai nostri
        pazienti di tutto il mondo.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MissionVision",
};
</script>

<style scoped>
.container {
  display: flex;
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 15px;
}

.column {
  flex: 1;
  padding: 20px;
}
.column h2 {
  font-weight: 600;
}

.column:first-child {
  border-right: 1px solid #e0e0e0;
}

h2 {
  color: #2c3e50;
  font-size: 24px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 2px solid #c49754;
}

p {
  color: #34495e;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .column:first-child {
    border-right: none;
    border-bottom: 1px solid #e0e0e0;
  }
}
</style>
