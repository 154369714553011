<template>
  <div id="faq-segment">
    <info-card v-if="infoCard == 'yes'"></info-card>

    <h3 v-if="faqHeader">{{ faqHeader }}</h3>

    <section class="accordion" v-for="f in faqS" :key="f.id">
      <div>
        <div class="accordion-item">
          <button class="accordion-header">
            {{ f.question }}
          </button>
          <div class="accordion-content">
            <p v-html="f.answer"></p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import InfoCard from "@/components/es/InfoCardES.vue";

export default {
  components: { InfoCard },
  props: {
    infoCard: {
      type: String,
      default: null,
    },
    faqHeader: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      faqS: [
        {
          id: 0,
          question:
            "¿Qué información necesito proporcionar para reservar una cita?",
          answer:
            "Solo necesitamos tu nombre completo y tu número de teléfono. Nuestro equipo de atención al paciente te llamará para agendar una cita en el momento que te convenga. Alternativamente, puedes establecer la hora de la cita tú mismo cuando la reserves a través de nuestro sitio web.",
        },
        {
          id: 1,
          question:
            "Me pongo nervioso cuando estoy solo. ¿Puedo traer a alguien conmigo?",
          answer:
            "¡Sí, por supuesto! Queremos que te sientas relajado y como en casa durante tu tratamiento en Health Medical Center. Aunque estamos seguros de que te sentirás relajado tan pronto como entres a nuestra oficina, puedes traer a tus seres queridos o amigos para apoyo moral.",
        },
        {
          id: 2,
          question: "¿Con qué antelación debo llegar a mi cita?",
          answer:
            "Depende de ti qué tan temprano quieras llegar a tu cita. Si deseas ahorrar tiempo, puedes llegar justo a la hora de tu cita. Sin embargo, te recomendamos llegar un poco antes para que puedas disfrutar de una taza de té o café con nosotros, relajarte en una de nuestras sillas de masaje o simplemente disfrutar del ambiente sereno y la música relajante en nuestra área de espera.",
        },
        {
          id: 3,
          question:
            "¿Qué pasa si necesito cambiar la fecha o la hora de mi cita?",
          answer:
            "Aunque no recomendamos cambiar la fecha u hora de la cita a última hora, entendemos que pueden surgir emergencias en cualquier momento. Sin embargo, te pedimos que por favor nos informes lo antes posible si no puedes asistir a tu cita. Llama al +355 69 20 96 720 y con gusto reprogramaremos tu cita.",
        },
        {
          id: 4,
          question:
            "¿Cuánto tiempo tarda en completarse un procedimiento de implante dental?",
          answer:
            "El proceso generalmente toma varios meses, con la primera fase siendo la colocación del implante y la fase de curación posterior. La restauración final se coloca después de que el implante se integra con el hueso.",
        },
        {
          id: 5,
          question:
            "¿Puedo combinar varios tratamientos dentales en una sola visita?",
          answer:
            "Sí, dependiendo de los tratamientos y tu caso específico, a menudo podemos combinar procedimientos para minimizar tus visitas y optimizar tu tiempo.",
        },
        {
          id: 6,
          question: "¿Está disponible la sedación para pacientes ansiosos?",
          answer:
            "Sí, ofrecemos opciones de sedación para pacientes que se sienten ansiosos durante los procedimientos dentales, para garantizar una experiencia cómoda y libre de estrés.",
        },
        {
          id: 7,
          question:
            "¿Necesito visa para viajar a Albania para tratamiento dental?",
          answer:
            "Los requisitos de visa dependen de tu país de origen. Muchos visitantes de Europa no requieren visa para estancias cortas. Por favor, consulta los requisitos más recientes o contáctanos para obtener ayuda.",
        },
        {
          id: 8,
          question:
            "¿Qué tan lejos está la clínica del aeropuerto y ofrecen transporte?",
          answer:
            "Nuestra clínica está convenientemente ubicada a 17 km del aeropuerto, y ofrecemos transporte gratuito desde y hacia el aeropuerto para nuestros pacientes internacionales.",
        },
        {
          id: 9,
          question: "¿Puedo obtener una consulta antes de llegar?",
          answer:
            "Sí, ofrecemos consultas virtuales gratuitas para pacientes internacionales para discutir tus necesidades dentales y proporcionar un plan de tratamiento preliminar antes de que viajes. Una vez que llegues a la clínica, te ofrecemos una consulta presencial gratuita junto con cualquier escaneo 3D o radiografía necesarios, asegurando una evaluación completa sin costo adicional.",
        },
        {
          id: 10,
          question:
            "¿Qué cuidados posteriores proporcionan después de mi procedimiento?",
          answer:
            "Proporcionamos instrucciones detalladas de post-tratamiento, y nuestro equipo siempre está disponible para consultas de seguimiento, ya sea en persona o en línea, para garantizar que tu recuperación sea exitosa.",
        },
        {
          id: 11,
          question: "¿Necesitaré regresar para visitas de seguimiento?",
          answer:
            "Para la mayoría de los tratamientos, el seguimiento se puede hacer de forma virtual. Sin embargo, para algunos procedimientos como implantes o Alineadores Transparentes, es posible que recomendemos un seguimiento presencial para asegurar los mejores resultados.",
        },
      ],
    };
  },
  computed: {
    faqs() {
      return this.faqItems && this.faqItems.length > 0
        ? this.faqItems
        : this.defFaqs;
    },
  },
  mounted() {
    const accordionHeaders = this.$el.querySelectorAll(".accordion-header");

    accordionHeaders.forEach((header) => {
      header.addEventListener("click", function () {
        const isActive = this.classList.contains("active");

        accordionHeaders.forEach((hdr) => {
          if (hdr !== this) {
            hdr.classList.remove("active");
            hdr.parentElement.classList.remove("hoverBl");
            hdr.nextElementSibling.style.maxHeight = null;
          }
        });

        const activeItem = this.nextElementSibling;

        if (isActive) {
          this.classList.remove("active");
          this.parentElement.classList.remove("hoverBl");
          activeItem.style.maxHeight = null;
        } else {
          this.classList.add("active");
          this.parentElement.classList.add("hoverBl");
          activeItem.style.maxHeight = activeItem.scrollHeight + "px";
        }
      });
    });
  },
};
</script>

<style scoped>
.accordion h3 {
  color: #6c63ff;
  font-size: 18px;
  margin-bottom: 10px;
}

.accordion-item {
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  font-family: Gilroy !important;
}

.accordion-item:first-of-type {
  border-top: 1px solid #e5e5e5;
}
.accordion-header {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-family: Gilroy;
  font-weight: 400;
  border: none;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-header:after {
  content: "+";
  font-size: 18px;
  color: #c49754;
}

.accordion-header.active:after {
  content: "-";
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background-color: #c49754;
}
.accordion-content > p {
  padding: 20px;
  line-height: 2;
}

.hoverBl {
  border: 1.4px solid #c49754 !important;
}

#faq-segment > h3 {
  color: #c49754;
  margin-bottom: 10px;
  font-size: 24px;
}
</style>
