<template>
  <div class="container">
    <div class="card">
      <h2 class="card-title">
        Pse Të Zgjidhni Health Medical Center për Trajtimin tuaj Dentar Jashtë?
      </h2>
      <p class="card-description">
        Ekipi ynë i specializuar ndërkombëtar siguron që ju të merrni kujdesin
        më të mirë të mundshëm. Ne përdorim teknologji të avancuar dhe ofrojmë
        trajtime me një pjesë të kostos krahasuar me shumë vende në Evropë dhe
        SHBA.
      </p>
      <ul class="card-list">
        <li>Specialistë dentarë me përvojë dhe njohuri ndërkombëtare</li>
        <li>Teknologji të avancuar dhe ambiente moderne</li>
        <li>
          Çmime të përballueshme krahasuar me shërbimet dentare në vende të
          tjera
        </li>
        <li>Akreditim dhe certifikata</li>
        <li>Staf shumëgjuhësh për komunikim të lehtë</li>
      </ul>
    </div>
    <div class="card">
      <h2 class="card-title">
        Kujdesi Dentar i Përballueshëm: Kurseni Më Shumë me HMC
      </h2>
      <p class="card-description">
        Me kursime deri në 70% në trajtimet dentare krahasuar me shumë vende
        perëndimore, HMC ju lejon të merrni kujdes të klasit të parë ndërsa
        mbani më shumë nga paratë tuaja të fituara me mund.
      </p>
    </div>
    <div class="card">
      <h2 class="card-title">
        E Bëjmë Udhëtimin Tuaj Të Lehtë: Ndihmë për Udhëtimin dhe Qëndrimin
      </h2>
      <p class="card-description">
        Ne ndihmojmë për ta bërë përvojën tuaj të turizmit dentar sa më të
        përshtatshme të jetë e mundur. Nga transferimet në aeroport deri tek
        akomodimet në hotel, ekipi ynë do t'ju ndihmojë në çdo hap të udhëtimit
        tuaj.
      </p>
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.card:nth-child(1) {
  grid-row: 1 / 3;
  grid-column: 1/ 2;
}

.card:nth-child(2) {
  grid-row: 1;
  grid-column: 2/ 3;
}

.card:nth-child(3) {
  grid-row: 2;
  grid-column: 2/ 2;
}

.card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;

  font-family: Arial, sans-serif;
}

.card-title {
  color: #2c3e50;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.card-description {
  color: #34495e;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.card-list {
  list-style-type: none;
  padding-left: 0;
}

.card-list li {
  color: #34495e;
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
  padding-left: 1.5rem;
  position: relative;
}

.card-list li::before {
  content: "✓";
  color: #3498db;
  position: absolute;
  left: 0;
  font-weight: bold;
}

@media all and (max-width: 768px) {
  .card {
    margin: 1rem;
    padding: 1.5rem;
  }

  .card-title {
    font-size: 1.25rem;
  }

  .card-description {
    font-size: 0.9rem;
  }

  .card-list li {
    font-size: 0.8rem;
  }
  .container > .card:nth-child(1) {
    grid-row: 1;
    grid-column: 1;
  }
  .container > .card:nth-child(2) {
    grid-row: 2;
    grid-column: 1;
  }
  .container > .card:nth-child(3) {
    grid-row: 3;
    grid-column: 1;
  }
}
</style>
