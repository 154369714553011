<template>
  <div class="container">
    <div class="column">
      <h2>Misioni Ynë</h2>
      <p>
        Misioni ynë është të ofrojmë kujdes dentar të klasit botëror me fokus në
        komoditetin e pacientit, cilësinë dhe përballueshmërinë. Ne jemi të
        përkushtuar për të ofruar trajtime të personalizuara që i përshtaten
        nevojave unike të çdo pacienti, duke siguruar rezultate të
        jashtëzakonshme. Qoftë kujdesi dentar apo shërbimet estetike të
        përzgjedhura, ne jemi të angazhuar për ta bërë çdo udhëtim të pacientëve
        tanë me ne të qetë, të kënaqshëm dhe transformues..
      </p>
    </div>
    <div class="column">
      <h2>Vizioni Ynë</h2>
      <p>
        Në Health Medical Center, ne e imagjinojmë veten si një destinacion
        kryesor për kujdesin e plotë dentar dhe estetik. Ne synojmë të ofrojmë
        trajtime të jashtëzakonshme që jo vetëm përmirësojnë shëndetin, por
        gjithashtu rrisin besimin dhe mirëqenien. Duke kombinuar inovacionin,
        ekspertizën dhe një qasje të personalizuar, ne përpiqemi të krijojmë
        buzëqeshje të bukura dhe përvoja që lënë një ndikim të qëndrueshëm tek
        pacientët tanë nga e gjithë bota.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MissionVision",
};
</script>

<style scoped>
.container {
  display: flex;
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 15px;
}

.column {
  flex: 1;
  padding: 20px;
}
.column h2 {
  font-weight: 600;
}

.column:first-child {
  border-right: 1px solid #e0e0e0;
}

h2 {
  color: #2c3e50;
  font-size: 24px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 2px solid #c49754;
}

p {
  color: #34495e;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .column:first-child {
    border-right: none;
    border-bottom: 1px solid #e0e0e0;
  }
}
</style>
