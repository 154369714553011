<template>
  <div class="container">
    <div class="card">
      <h2 class="card-title">
        ¿Por qué elegir Health Medical Center para tu tratamiento dental en el
        extranjero?
      </h2>
      <p class="card-description">
        Nuestro experimentado equipo de profesionales dentales capacitados
        internacionalmente asegura que recibas la mejor atención posible.
        Utilizamos tecnología de vanguardia y ofrecemos tratamientos a una
        fracción del costo en comparación con muchos países de Europa y Estados
        Unidos.
      </p>
      <ul class="card-list">
        <li>
          Especialistas dentales internacionales experimentados (con
          credenciales y reconocimiento internacional)
        </li>
        <li>Tecnología avanzada e instalaciones modernas</li>
        <li>
          Precios asequibles en comparación con los servicios dentales en otros
          países
        </li>
        <li>Acreditaciones y certificaciones</li>
        <li>Personal multilingüe para una comunicación fácil</li>
      </ul>
    </div>
    <div class="card">
      <h2 class="card-title">Cuidado dental asequible: ahorra más con HMC</h2>
      <p class="card-description">
        Con ahorros de hasta el 70% en tratamientos dentales en comparación con
        muchos países occidentales, HMC te permite recibir atención de primer
        nivel mientras conservas más de tu dinero ganado con esfuerzo.
      </p>
    </div>
    <div class="card">
      <h2 class="card-title">
        Hacemos tu viaje más fácil: asistencia con viajes y alojamiento
      </h2>
      <p class="card-description">
        Ayudamos a hacer tu experiencia de turismo dental lo más conveniente
        posible. Desde traslados al aeropuerto hasta alojamiento en hoteles,
        nuestro equipo te asistirá en cada paso de tu viaje.
      </p>
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.card:nth-child(1) {
  grid-row: 1 / 3;
  grid-column: 1/ 2;
}

.card:nth-child(2) {
  grid-row: 1;
  grid-column: 2/ 3;
}

.card:nth-child(3) {
  grid-row: 2;
  grid-column: 2/ 2;
}

.card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;

  font-family: Arial, sans-serif;
}

.card-title {
  color: #2c3e50;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.card-description {
  color: #34495e;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.card-list {
  list-style-type: none;
  padding-left: 0;
}

.card-list li {
  color: #34495e;
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
  padding-left: 1.5rem;
  position: relative;
}

.card-list li::before {
  content: "✓";
  color: #3498db;
  position: absolute;
  left: 0;
  font-weight: bold;
}

@media all and (max-width: 768px) {
  .card {
    margin: 1rem;
    padding: 1.5rem;
  }

  .card-title {
    font-size: 1.25rem;
  }

  .card-description {
    font-size: 0.9rem;
  }

  .card-list li {
    font-size: 0.8rem;
  }
  .container > .card:nth-child(1) {
    grid-row: 1;
    grid-column: 1;
  }
  .container > .card:nth-child(2) {
    grid-row: 2;
    grid-column: 1;
  }
  .container > .card:nth-child(3) {
    grid-row: 3;
    grid-column: 1;
  }
}
</style>
